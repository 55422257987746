import Vue from 'vue'
import VueRouter from 'vue-router'
import VideoView from '../views/VideoView'
import UserView from '../views/UserView'
import GameView from '../views/GameView'
import QTView from '../views/QTView'
import CommunityView from '../views/CommunityView'
import LeaderboardView from '../views/LeaderboardView'
import PlayerView from '../views/PlayerView'
import About from '../views/About.vue'
import VideoService from '../services/VideoService.js'
import ClipService from '../services/ClipService.js'
import ImgurService from '../services/ImgurService'

Vue.use(VueRouter)

function videoViewProps (route) {
  var timeInit

  if (route.query.t &&
      ['week', 'day', 'all', 'month', 'year'].includes(route.query.t)) {
    timeInit = route.query.t
  }

  var service
  if (route.params.content === 'videos') {
    service = VideoService
  } else {
    service = ClipService
  }

  return {
    sortByInit: route.params.sortByUrl,
    timeInit: timeInit,
    errorInit: route.query.err,
    service: service,
    contentType: route.params.content
    // tabItems
  }
}

function gameViewProps (route) {
  var timeInit

  if (route.query.t &&
      ['week', 'day', 'all', 'month', 'year'].includes(route.query.t)) {
    timeInit = route.query.t
  }

  var props = {}
  if (route.params.content === 'clips') {
    props.contentType = 'clips'
    props.service = ClipService
    props.submitTitleLabel = 'Submit a Twitch / YouTube Clip'
    props.submitInputLabel = 'Clip URL'
    props.submitErrorLabel = 'Please submit a valid Twitch or YouTube clip URL'
    props.submitUserTitleLabel = 'Title (Leave blank to use original title)'
    /* eslint-disable no-useless-escape */
    props.validUrlPattern = [
      /^.*(?:clips.twitch.tv|twitch.tv\/.*\/clip)\/([^\?]*)(?:\?.*)?$/, // eslint-disable-no-useless-escape
      /^.*(youtube.com\/clip\/)([^#\&\?]*).*/ // eslint-disable-no-useless-escape
    ]
  } else {
    props.contentType = 'videos'
    props.service = VideoService
    props.submitTitleLabel = 'Submit a YouTube Video'
    props.submitInputLabel = 'YouTube Video URL'
    props.submitErrorLabel = 'Please submit a valid YouTube video URL'
    /* eslint-disable no-useless-escape */
    props.validUrlPattern = [
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/ // eslint-disable-no-useless-escape
    ]
  }

  return {
    sortByInit: route.params.sortByUrl,
    timeInit: timeInit,
    errorInit: route.query.err,
    gameName: route.params.game,
    service: props.service,
    contentType: props.contentType,
    isWatchedTab: props.isWatchedTab,
    submitTitleLabel: props.submitTitleLabel,
    submitUserTitleLabel: props.submitUserTitleLabel,
    submitInputLabel: props.submitInputLabel,
    submitErrorLabel: props.submitErrorLabel,
    validUrlPattern: props.validUrlPattern
    // tabItems: tabItems
  }
}

function qtProps (route) {
  var timeInit
  var contentType

  if (route.query.t &&
      ['week', 'day', 'all', 'month', 'year'].includes(route.query.t)) {
    timeInit = route.query.t
  }

  if (route.params.game === 'avghans' && !route.params.content) {
    contentType = 'videos'
  } else {
    contentType = route.params.content ? route.params.content.toLowerCase() : route.params.content
  }

  return {
    sortByInit: route.params.sortByUrl ? route.params.sortByUrl.toLowerCase() : route.params.sortByUrl,
    timeInit: timeInit,
    errorInit: route.query.err,
    gameName: route.params.game ? route.params.game.toLowerCase() : route.params.game,
    contentType: contentType
  }
}

function userProfileProps (route) {
  var timeInit

  if (route.query.t &&
      ['week', 'day', 'all', 'month', 'year'].includes(route.query.t)) {
    timeInit = route.query.t
  }

  var service
  if (route.params.content === 'videos') {
    service = VideoService
  } else if (route.params.content === 'emotes') {
    service = ImgurService
  } else {
    // favorites tech debt
    service = ClipService
  }

  return {
    sortByInit: route.params.sortByUrl,
    timeInit: timeInit,
    errorInit: route.query.err,
    usernameInit: route.params.username,
    service: service,
    contentType: route.params.content
    // tabItems: tabItems
  }
}

function playerProps (route) {
  var timeInit
  var isUserPlayer = false

  if (route.query.t &&
      ['week', 'day', 'all', 'month', 'year'].includes(route.query.t)) {
    timeInit = route.query.t
  }

  if (route.params.username) {
    isUserPlayer = true
  }

  return {
    timePropInit: timeInit,
    gameName: route.params.gameName,
    username: route.params.username,
    sortByInit: route.params.sortByInit,
    contentRoute: route.params.content,
    isUserPlayer: isUserPlayer
  }
}

const routes = [
  {
    path: '/:content(videos|clips)?/:sortByUrl(hot|new|top)?',
    name: 'Videos',
    component: VideoView,
    props: videoViewProps
  },
  {
    path: '/:game(smash|atrioc|valorant|gaming)/:content(videos|clips)?/:sortByUrl(hot|new|top)?',
    name: 'GameVideos',
    component: GameView,
    props: gameViewProps
  },
  {
    path: '/:gameName?/player/:content(clips|videos|santa)?/:sortByInit(hot|new|top)?',
    name: 'Player',
    component: PlayerView,
    props: playerProps
  },
  {
    path: '/communities',
    name: 'Community',
    component: CommunityView
  },
  // TODO: game param? handle incorrect param?
  {
    path: '/leaderboard/:game?',
    name: 'Leaderboard',
    component: LeaderboardView,
    props: true
  },
  {
    path: '/user/:username/player/:content(clips|videos|favorites|emotes)?/:sortByUrl(hot|new|top)?',
    name: 'UserPlayer',
    component: PlayerView,
    props: playerProps
  },
  {
    path: '/user/:username/:content(clips|videos|favorites|emotes)?/:sortByUrl(hot|new|top)?',
    name: 'UserProfile',
    component: UserView,
    props: userProfileProps
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:game/:content?/:sortByUrl(hot|new|top)?',
    name: 'QTVideos',
    component: QTView,
    props: qtProps
  },
  {
    path: '/sadge',
    name: 'sadge',
    component: About
  },
  {
    path: '*',
    name: 'catch_all',
    redirect: 'sadge'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
