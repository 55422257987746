<template>
<!-- style="max-width: 1300px; margin: auto;" -->
  <div v-infinite-scroll="getVideos" infinite-scroll-immediate-check="false" infinite-scroll-disabled="reloadBusy">

    <v-dialog v-model="vDialog" width="400px" >
      <LoginCard v-if="!userDetails">
      </LoginCard>
    </v-dialog>

    <v-container class="" style="max-width: 1100px;">
      <v-card flat outlined rounded>
        <v-card-title class="pb-1">
          <v-avatar class="mr-4" size="60">
            <img :src="avatarUrl" alt="User Avatar">
          </v-avatar>
          <h2>{{ display_name }}</h2>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large :color="badge_color" :style="badge_style" v-on="on" v-bind="attrs">
                {{ badge_icon }}
              </v-icon>
            </template>
            <span style="font-size: 14px;">
              {{ badge_text }}
            </span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn icon @click="extProfileIconClicked" class="ml-2">
            <v-icon v-if="authType === 2">
              mdi-twitch
            </v-icon>
            <v-icon v-else-if="authType === 1">
              mdi-twitter
            </v-icon>
            <v-icon v-else-if="authType === 3">
              mdi-google
            </v-icon>
            <v-icon v-else>
              mdi-discord
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2 pb-4">
          <v-icon>
            mdi-star-face
          </v-icon>
          Score: {{ score }}
        </v-card-text>

        <v-spacer></v-spacer>

        <v-card-actions class="py-0 pl-0">
          <!-- <v-tabs hide-slider active-class="white--text secondary">
            <v-tab :to="'/user/' + username + '/clips'">Clips</v-tab>
            <v-tab :to="'/user/' + username +  '/videos'">Videos</v-tab>
            <v-tab :to="'/user/' + username + '/emotes'">Emotes</v-tab>
          </v-tabs> -->
          <v-tabs v-model="tabModel" color="white" active-class="white--text" :show-arrows="true">
            <v-tab v-for="(item, index) in subpages" :key="index" :value="item.order">{{ item.name }}</v-tab>
          </v-tabs>
        </v-card-actions>
      </v-card>

      <v-toolbar flat rounded outlined dense class="mt-4">

        <!-- @@@@@@@@@@@@@   DESKTOP sortBy   @@@@@@@@@@@@@  -->
        <v-chip-group
          class="mr-4"
          v-if="!$vuetify.breakpoint.xs"
          mandatory
          active-class="secondary"
          v-model="sortBy"
          v-on:change="selectorChange">
          <v-chip
            color="transparent"
            v-for="(item, index) in sortByItems"
            :key="index"
            :value="item.value"
          >
            <v-icon left small>
                {{ item.icon }}
            </v-icon>
            {{ item.label }}
          </v-chip>
        </v-chip-group>

        <!-- @@@@@@@@@@@@@   MOBILE sortBy   @@@@@@@@@@@@@  -->
        <v-menu v-else bottom :offset-y="true" transition="slide-y-transition">

          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs"  class="mr-3" color="">
              <v-icon left small>
                {{ sortByItems[sortBy].icon }}
              </v-icon>
              {{ sortByItems[sortBy].label }}
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>

          <v-list class="cursor">
            <v-list-item-group v-model="sortBy" mandatory v-on:change="selectorChange()">
              <v-list-item v-for="(sort, index) in sortByItems" :key="index" :value="sort.value">
                <v-icon left small>
                  {{ sort.icon }}
                </v-icon>
                <v-list-item-title style="font-size: 14px;">{{ sort.label }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- Controlled by timeDisplay computed -->
        <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-1">

          <template v-slot:activator="{ on, attrs }">
            <v-chip :class="timeDisplay" v-on="on" v-bind="attrs">
              {{ time.label }}
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>

          <v-list class="cursor">
            <v-list-item-group v-model="time" mandatory v-on:change="selectorChange()">
              <v-list-item v-for="(item, index) in timeItems" :key="index" :value="item">
                <v-list-item-title style="font-size: 14px;">{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- ####### typeId ####### -->
        <v-menu bottom :offset-y="true" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs"  class="mr-3">
              <v-icon left small>
                {{ type.icon }}
              </v-icon>
              {{ type.name }}
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>

          <v-list class="cursor">
            <v-list-item-group v-model="typeIndex" mandatory v-on:change="selectorChange()">
              <v-list-item v-for="(item, index) in typeItems" :key="index" :value="index">
                <v-icon left small>
                  {{ item.icon }}
                </v-icon>
                <v-list-item-title style="font-size: 14px;">{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar>

      <!-- area under toolbar -->
      <div style="display: flex;">
        <v-col class="ma-0 pa-0">
          <v-select
            class="mt-4 mb-1"
            outlined
            hide-details
            dense
            placeholder="Filter by Duration..."
            :items="durationItems"
            v-model="duration"
            @change="categoryChange"
            :menu-props="{ 'offset-y': true, 'transition': 'slide-y-transition' }"
            width="170px"
            style="width: 170px; font-size: 12px !important; font-weight: bold;"
            v-show="contentType === 'videos'"
          >
          </v-select>
        </v-col>

        <v-spacer></v-spacer>
        <v-switch
          class="ml-3 mb-1 mt-4"
            hide-details
            v-model="playerSwitch"
            label="Disable Player"
          v-if="!this.$vuetify.breakpoint.mdAndDown"
          >
        </v-switch>
      </div>

      <v-row v-if="!errorState && posts.length !== 0" wrap align-content-start class="mt-1 mt-HERE">
        <!-- class="my-2 px-2 five-cols four-cols three-cols two-cols one-col" -->
        <v-col v-for="(post, index) in posts" :key=index class="three-cols-b two-cols-b one-col-b">
          <!-- is_user_route? -->
          <VideoCard
            v-if="contentType !== 'emotes'"
            v-on:content-deleted="contentDeleted(index, post.postId)"
            v-on:primary-chip-selected="primaryChipSelected(post.ui_route_path)"
            v-on:secondary-chip-selected="primaryChipSelected(post.ui_route_path)"
            v-on:content-vote="contentVote(index, ...arguments)"
            v-on:clip-clicked="clipClicked(index)"
            v-on:favorite-clicked="favoriteClicked(index, post.postId)"
            v-bind="post"
            :primaryChipColor="post.chip_color"
            :chipLabel="post.community_name"
            :secondaryChipColor="post.cat_color"
            :secondaryChipLabel="post.category"
            :dbUpvote="service.upvote"
            :dbDownvote="service.downvote"
            :contentRoute="contentType"
            :duration="post.duration"
            :submitterId="post.userId"
            :submitterName1="post.submitterName1"
            :user_name="post.user_name"
            :viewCount="post.view_count"
            :context="post.context"
            :isEmote="contentType === 'emotes'"
            :playerSwitch="playerSwitch"
          >
          </VideoCard>
          <ImgurCard
            v-else
            v-on:content-deleted="contentDeleted(index, post.postId)"
            v-on:primary-chip-selected="primaryChipSelected(post.ui_route_path)"
            v-on:secondary-chip-selected="primaryChipSelected(post.ui_route_path)"
            v-bind="post"
            :primaryChipColor="post.chip_color"
            :chipLabel="post.community_name"
            :secondaryChipColor="post.cat_color"
            :secondaryChipLabel="post.category"
            :dbUpvote="service.upvote"
            :dbDownvote="service.downvote"
            :contentRoute="contentType"
            :duration="post.duration"
            :submitterId="post.userId"
            :submitterName1="post.submitterName1"
            :user_name="post.user_name"
            :viewCount="post.view_count"
            :isEmote="contentType === 'emotes'"
            :playerSwitch="playerSwitch"
            :sizeSwitch="false"
            :upvotes="post.upvotes"
            :init_upvoted="post.init_upvoted"
            :width="'365px'"
            :layout="1"
          >
          </ImgurCard>
        </v-col>
      </v-row>
      <span v-else>
        <MainViewError v-if="notFoundState" :message="'this user doesn\'t exist'" :refresh="false"></MainViewError>
        <MainViewError v-else-if="errorState" :message="'something went wrong getting ' + contentType +  '... please try again later'"></MainViewError>
        <MainViewError v-else :message="'looks like ' + display_name + ' has not posted any ' + contentType + '!'"></MainViewError>
      </span>

    </v-container>
  </div>
</template>

<script>
// break the above out to another component?
import LoginCard from '../components/LoginCard'
import VideoCard from '../components/VideoCard'
import ImgurCard from '../components/ImgurCard'
import MainViewError from '../components/MainViewError'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import UserService from '../services/UserService'
import urlUtilsMixin from '../utils/urlUtils'
import VueRouter from 'vue-router'

export default {
  name: 'UserView',

  components: { VideoCard, MainViewError, LoginCard, ImgurCard },

  mixins: [urlUtilsMixin],

  data () {
    return {
      posts: [],
      avatarUrl: '',
      subpages: [],
      authType: null,
      dialog: false,
      playerSwitch: false,
      tabModel: 0,
      sortByItems: {
        hot: {
          label: 'Hot',
          value: 'hot',
          icon: 'mdi-fire'
        },
        new: {
          label: 'New',
          value: 'new',
          icon: 'mdi-decagram-outline'
        },
        top: {
          label: 'Top',
          value: 'top',
          icon: 'mdi-transfer-up'
        }
      },
      timeItems: {
        day: {
          label: 'Today',
          query: 'day'
        },
        week: {
          label: 'Week',
          query: 'week'
        },
        month: {
          label: 'Month',
          query: 'month'
        },
        year: {
          label: 'Year',
          query: 'year'
        },
        all: {
          label: 'All Time',
          query: 'all'
        }
      },
      typeItems: [
        {
          name: 'All',
          id: null,
          icon: 'mdi-web'
        },
        {
          name: 'Games',
          id: 1,
          icon: 'mdi-google-controller'
        },
        {
          name: 'Streamers',
          id: 2,
          icon: 'mdi-video-outline'
        }
      ],
      durationItems: [
        {
          text: 'All Durations',
          value: null
        },
        {
          text: 'Less than 10 mins',
          value: 10
        },
        {
          text: '10 - 30 mins',
          value: 30
        },
        {
          text: '30 mins - 1 hr',
          value: 60
        },
        {
          text: 'More than 1 hr',
          value: 61
        }
      ],
      duration: undefined,
      offset: 0,
      userId: -1,
      score: 0,
      // getVideos error
      errorState: 0,
      // userNotFound
      notFoundState: 0,
      sortBy: this.sortByInit,
      username: this.usernameInit,
      timeDataInit: this.timeInit,
      typeIndex: 0,
      reloadBusy: true,
      badge_icon: 'user not found :(',
      badge_style: null,
      badge_color: null,
      badge_text: null,
      display_name: null
    }
  },

  methods: {

    ...mapActions(['triggerErrorNotification']),

    ...mapMutations(['setErrorNotification', 'setSuccessNotification', 'setPlayerInfo']),

    getVideos: async function () {
      try {
        var timeParam = null
        if (this.sortBy === 'top') {
          timeParam = this.time.query
        }
        const res = await this.service.getUserContent(this.sortBy, this.userId, this.offset, timeParam, this.duration, this.type.id, this.isFavorites)
        this.posts = this.posts.concat(res.data)
        this.offset += res.data.length
        if (this.reloadBusy) {
          this.reloadBusy = false
        }
      } catch (err) {
        this.errorState = 1
      }
    },

    clipClicked: async function (index) {
      this.setPlayerInfo({
        community: {
          subpages: this.subpages
        },
        posts: this.posts,
        postIndex: index,
        modEnabled: false,
        offset: this.offset,
        creatorFilter: this.creatorFilter,
        creatorList: this.creatorList,
        categoryId: null
      })

      var routeConfig = '/user/' + this.username + '/player/' + this.contentType + '/' + this.sortBy
      if (this.sortBy === 'top') {
        routeConfig = routeConfig + '?t=' + this.time.query
      }
      this.$router.push(routeConfig)
    },

    sortBySelected: function (sort) {
      this.sortBy = sort
      this.selectorChange()
    },

    getSpecificUser: async function () {
      try {
        const res = await UserService.getSpecificUser(this.username)
        this.avatarUrl = res.data.avatarUrl
        // exposing user Id on the api call here? honestly who cares tho....
        this.userId = res.data.userId

        // this is way more ridiculous
        this.score = parseInt(res.data.video_score) + parseInt(res.data.clips_score)
        this.authType = res.data.auth_type
        this.badge_icon = res.data.badge_icon
        this.badge_color = res.data.badge_color
        this.badge_style = res.data.badge_style
        this.badge_text = res.data.badge_text
        this.display_name = res.data.display_name
        this.subpages = res.data.subpages
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.username = 'User Not Found'
          this.avatarUrl = 'https://pbs.twimg.com/profile_images/1487506672926998528/BqoO7vZ__normal.jpg'
          this.notFoundState = 1
        }
      }
    },

    // push the route on change, watcher on route causes the reload. On duplicated nav failure manually reload
    selectorChange: function () {
      var routeConfig = {
        name: 'UserProfile',
        params: {
          content: this.contentType,
          sortByUrl: this.encodeToUrl(this.sortBy),
          username: this.username
        }
      }

      if (this.sortBy === 'top') {
        routeConfig.query = {
          t: this.time.query
        }
      }

      this.$router.push(routeConfig).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          this.offset = 0
          this.posts = []
          this.reloadBusy = true
          this.getVideos()
        } else {
          this.setErrorNotification({ trigger: true })
        }
      })
    },

    // for duration
    categoryChange: function () {
      this.offset = 0
      this.posts = []
      this.reloadBusy = true
      this.getVideos() // await?
    },

    primaryChipSelected: function (route) {
      var routeConfig = '/' + route + '/' + this.contentType
      this.$router.push(routeConfig)
    },

    extProfileIconClicked: function () {
      if (this.authType === 2) {
        window.open('https://twitch.tv/' + this.username, '_blank')
      } else if (this.authType === 1) {
        window.open('https://twitter.com/' + this.username, '_blank')
      }
    },

    // on content-deleted emit event from VideoCard
    contentDeleted: async function (deleteIndex, postId) {
      try {
        await this.service.deleteContent(postId)
        this.posts.splice(deleteIndex, 1)
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    contentVote: function (index, upvotes, upvoted, downvoted) {
      if (upvoted) {
        this.posts[index].init_upvoted = 1
      } else if (downvoted) {
        this.posts[index].init_upvoted = -1
      } else {
        this.posts[index].init_upvoted = null
      }
      this.posts[index].upvotes = upvotes.toString()
    },

    favoriteClicked: async function (index, postId) {
      await this.service.favoriteContent(postId)
      this.posts[index].favorited = !this.posts[index].favorited
    }
  },

  props: {
    sortByInit: {
      type: String,
      default: 'hot'
    },
    timeInit: {
      type: String,
      default: 'all'
    },
    errorInit: Boolean,
    // favorites tech debt, should use utils/ContentService.js for service
    service: Object,
    usernameInit: String,
    contentType: {
      type: String,
      default: 'clips'
    }
  },

  computed: {
    ...mapGetters(['userDetails']),

    ...mapState({
      vDialog: state => state.twitterAuth.vDialog
    }),

    errorNotification: {
      get () {
        return this.$store.state.twitterAuth.errorNotification
      },

      set (value) {
        this.setErrorNotification({ trigger: value })
      }
    },

    timeDisplay: function () {
      return this.sortBy === 'top' ? 'ml-n1 mr-3 ml-sm-n4 mr-sm-6' : 'd-none'
    },

    time: {
      get () {
        return this.timeItems[this.timeDataInit]
      },
      set (newObj) {
        this.timeDataInit = newObj.query
        return newObj
      }
    },

    type: {
      get () {
        return this.typeItems[this.typeIndex]
      },
      set (newObj) {
        this.typeIndex = newObj
      }
    },

    // favorites tech debt
    isFavorites: function () {
      if (this.subpageData.type === 4) {
        return true
      }
      return undefined
    },

    subpageData: function () {
      return this.subpages[this.tabModel] || {
        route: '',
        order: 0
      }
    }
  },

  // beforeMount was here before, why?
  created: async function () {
    try {
      if (this.errorInit) {
        this.setErrorNotification({ trigger: this.errorInit })
      }
      await this.getSpecificUser()
      this.subpages.forEach((el, index) => {
        if (el.route === this.contentType) {
          this.tabModel = index
        }
      })

      this.reloadBusy = true
      await this.getVideos()
    } catch (error) {
      this.errorState = 1
    }
  },

  watch: {
    // this is where it needs to happen
    // needs to be a data username ?? in order to react to route changes e.g. crazy shit
    $route: async function (to, from) {
      if (to.params.sortByUrl) {
        this.sortBy = to.params.sortByUrl
      }

      if (to.params.username !== from.params.username) {
        this.username = to.params.username
        await this.getSpecificUser()
      }

      if (to.params.content !== 'videos') {
        this.duration = undefined
      }

      this.offset = 0
      this.posts = []
      this.reloadBusy = true
      this.getVideos()
    },

    tabModel: function (to, from) {
      var sortByParam = ''
      if (this.$router.currentRoute.params && this.$router.currentRoute.params.sortByUrl) {
        sortByParam = '/' + this.$router.currentRoute.params.sortByUrl
      }
      this.$router.push('/user/' + this.username + '/' + this.subpageData.route + sortByParam).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // if nav is duplicated, do nothing
        }
      })
    }
  }
}
</script>

<style scoped>

  .sort-by {
    position: relative;
    width: 100% !important;
  }

  .categories {
    width: 10% !important;
  }

  .heading {
    float: left;
  }

  .plus-icon {
    float: right;
  }

  .header-div {
    overflow: overlay;
  }
/*
  .selectors {
    margin: 0 0 0 9em;
    padding: 0;
    width: 9%
  } */

  .no-space {
    margin: 0;
    padding: 0;
  }

  .sort-by-group {
    width: 50%;
  }

  /* used to be 1099, why? */
  @media only screen and (min-width: 991px) {
    .three-cols-b {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 990px) {
    .two-cols-b {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }

  @media only screen and (max-width: 600px) {
    .one-col-b {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @media only screen and (min-width: 1600px) and (max-width: 2000px) {
    .five-cols {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }
  }

  @media only screen and (min-width: 1301px) and (max-width: 1599px) {
    .four-cols {
      width: 25%;
      max-width: 25%;
      flex-basis: 25%;
    }
  }

  @media only screen and (min-width: 990px) and (max-width: 1300px) {
    .three-cols {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 989px) {
    .two-cols {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }

  @media only screen and (max-width: 599px) {
    .one-col {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
</style>
