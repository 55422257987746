<template>
<!-- margin: 16px 72px 0px 72px; -->
  <div style="display: flex; overflow-x: hidden; max-height: calc(100vh - 64px); max-width: 1700px" class="mx-auto mt-4 pl-4">

    <!-- ### SUBMISSION OR LOGIN ### -->
    <v-dialog v-model="vDialog" width="400px">
      <SubmissionCard
        v-if="userDetails && gameName"
        v-on:upload-video="uploadVideo"
        :titleLabel="subpageData.submitTitleLabel"
        :inputLabel="subpageData.submitInputLabel"
        :userTitleLabel="subpageData.submitUserTitleLabel"
        :errorLabel="subpageData.submitErrorLabel"
        :categoryItems="submitCategories"
        :validUrlPattern="subpageData.validUrlPatterns"
      >
      </SubmissionCard>
      <CommunityRedirectCard v-else-if="userDetails"
        :contentType="contentRoute"
      >
      </CommunityRedirectCard>
      <LoginCard v-else>
      </LoginCard>
    </v-dialog>

    <div style="flex: 2 1 66%; justify-content: end; !important" class="mr-4">
      <div :style="'display: flex; justify-content: space-between; align-items: center; width: ' + playerWidth + 'px'" class="mb-2 mx-auto">
        <v-btn icon @click="returnToMainPage">
          <v-icon large>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <div style="display: flex; align-items: center;">
          <v-col class="ml-0 mr-4 mt-0 mb-0 pa-0" v-if="(subpageData.type === 1 && community.game_id == 3)">
            <v-select
              class="ma-0"
              outlined
              hide-details
              dense
              placeholder="Filter by Creator..."
              :items="creatorList"
              v-model="creatorFilter"
              @change="fullReload()"
              :menu-props="{ 'offset-y': true, 'transition': 'slide-y-transition' }"
              width="170px"
              style="width: 170px; font-size: 12px !important; font-weight: bold"
            >
            </v-select>
          </v-col>
          <v-switch
              v-if="isMod"
              hide-details
              v-model="enableModView"
              label="Mod"
              class="ma-0 pr-3"
          >
          </v-switch>
        </div>
      </div>
      <v-card color="mx-auto" :width="playerWidth" max-width="1161.7px">
        <iframe
          v-if="subpageData.type === 1 || isFavorites"
          id="player"
          ref="player"
          :src="embedSrc"
          :width="playerWidth"
          :height="playerHeight"
          allowfullscreen
          frameborder="0"
          style="display: block; max-width: 1161.7px;"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          class="mx-auto"
        >
        </iframe>

        <div ref="player" id="player" style="max-width: 1161.7px">
          <youtube
            ref="ytplayer"
            id="ytplayer"
            :video-id="embedSrc"
            :width="playerWidth"
            :height="playerHeight"
            :player-vars="{autoplay: 1}"
            v-if="contentRoute === 'videos'"
            @ended="videoEnded"
          >
          </youtube>
        </div>

        <div class="pa-3">
          <div style="display: flex; align-items: center;" class="ma-0 pa-0 justify-space-between">
            <v-card-title v-line-clamp="2" class="px-0 pt-0 mb-3 font-weight-bold custom-title" style="display: inline-block; word-break: keep-all !important;">
              {{ selectedPost.title }}
            </v-card-title>
            <div class="mt-n3" style="flex-shrink: 0;">
              <v-btn :disabled="posts.length === 0" icon style="display: inline;" :color="upvoteColor" :ripple="{ class: 'green--text' }" @click.prevent="upvote">
                <v-icon v-if="!enableModView" large>mdi-chevron-up</v-icon>
                <v-icon v-else large>mdi-chevron-double-up</v-icon>
              </v-btn>
              <v-card-subtitle style="display: inline; font-size: 16px" class="pa-0">{{ selectedPostLocalVotes }}</v-card-subtitle>
              <v-btn :disabled="posts.length === 0" style="display: inline;" icon :color="downvoteColor" :ripple="{ class: 'red--text' }" @click.prevent="downvote">
                <v-icon v-if="!enableModView" large>mdi-chevron-down</v-icon>
                <v-icon v-else large>mdi-chevron-double-down</v-icon>
              </v-btn>
            </div>
          </div>

          <v-card-subtitle class="pa-0" style="margin-bottom: 10px;">
            <a :href="this.selectedPostCreatorInfo.link" target="_blank" @click.stop @mousedown.stop @touchstart.stop :style="'color: white; background-color: ' + selectedPostCreatorInfo.color" class="creator-link user-text creator-background">
              <v-icon dense :style="selectedPostCreatorInfo.iconStyle">
                {{ selectedPostCreatorInfo.icon }}
              </v-icon>
              {{ selectedPost.creatorName }}
            </a>
          </v-card-subtitle>

          <div style="display: flex; align-items: center;" class="ma-0 pa-0 justify-space-between">
            <div style="display: inline-block">
              <v-card-subtitle v-if="selectedPost.view_count" class="pa-0 mx-0 mt-0 text--secondary" style="margin-bottom: -3px">
                {{ selectedPostViews }} {{ viewSuffix }}
              </v-card-subtitle>

              <v-card-subtitle class="pa-0 text--secondary" style="margin-bottom: -3px;" @click.stop @mousedown.stop @touchstart.stop>
                submitted by:
                <router-link :to="'/user/' + this.selectedPost.user_name + '/' + this.contentRoute" class="user-text submit-by">
                  {{ selectedPost.submitterName1 }}
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-show="selectedPost.badge_icon" :small="selectedPost.badge_icon === 'mdi-sword mdi-flip-h'" :dense="selectedPost.badge_icon === 'mdi-discord'" :color="selectedPost.badge_color" :style="selectedPost.badge_style" v-on="on" v-bind="attrs">
                        {{ selectedPost.badge_icon }}
                      </v-icon>
                    </template>
                    <span style="font-size: 14px;">
                      {{ selectedPost.badge_text }}
                    </span>
                  </v-tooltip>
                </router-link>
              </v-card-subtitle>

              <v-card-subtitle class="pa-0 text--secondary" @click.stop @mousedown.stop @touchstart.stop>
                {{ selectedPostSubmitDate }}
              </v-card-subtitle>
            </div>

            <div style="justify-self: center; align-self: end">
              <v-tooltip top v-if="this.contentRoute !== 'videos'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mr-12" @click.prevent="favoriteClicked" v-on="on" v-bind="attrs">
                    <v-icon large>{{ selectedPost.init_favorited ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
                    </v-btn>
                </template>
                <span style="font-size: 14px;">
                  Favorite
                </span>
              </v-tooltip>
              <span v-if="enableModView">
                <v-tooltip top v-if="!selectedPost.watched">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" :ripple="{ class: 'green--text' }" class="mr-3" @click.prevent="markWatched(false, true)" v-on="on" v-bind="attrs">
                      <v-icon large>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 14px;">
                    Watched
                  </span>
                </v-tooltip>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mr-3" @click.prevent="markWatched(false, false)" v-on="on" v-bind="attrs">
                      <v-icon large>mdi-undo</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 14px;">
                    Undo Watched
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon :ripple="{ class: 'green--text' }" class="mr-3" @click.prevent="markWatched(true, true)" v-on="on" v-bind="attrs">
                      <v-icon large>mdi-eye-off</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 14px;">
                    Hide
                  </span>
                </v-tooltip>
              </span>
              <v-tooltip top v-if="enableModView || (userDetails && (selectedPost.user_name === userDetails.username))">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mr-5" color="red" :ripple="{ class: 'red--text' }" @click.prevent="deleteContent" v-on="on" v-bind="attrs">
                    <v-icon large>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 14px;">
                    Delete
                </span>
              </v-tooltip>
              <v-chip style="align-self: end;" :color="selectedPost.cat_color" @click.prevent="$emit('primary-chip-selected')" @click.stop @mousedown.stop @touchstart.stop>
              {{ selectedPost.category }}
            </v-chip>
            </div>

            <!-- <v-chip disabled style="align-self: end;" :color="selectedPost.cat_color" @click.prevent="$emit('primary-chip-selected')" @click.stop @mousedown.stop @touchstart.stop>
              {{ selectedPost.category }}
            </v-chip> -->
          </div>
        </div>
      </v-card>

      <!-- <iframe
        :src="'https://clips.twitch.tv/embed?clip=' + slug + '&parent=' + parentUrl + '&autoplay=true&muted=false'"
        height="400"
        width="800"
        style="display: block;"
        class="ma-auto"
        allowfullscreen
        allow="autoplay"
        frameborder="0"
      >
      </iframe> -->

      <!-- <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/HYCdxxMbrqw?clip=UgkxECA1UFovUWUV4qw9jgYEKR_F5UFNtR-l&amp;autoplay=true"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe> -->
    </div>
    <!-- overflow: hidden; overflow-x: hidden; -->
    <!-- TODO mt-12 should be 11 for vids, (due to no filter by creator) - figure it out -->
    <div style="overflow-y: hidden; overflow-x: hidden;" class="mt-12 list-flex list-flex2">
      <v-toolbar outlined rounded dense>
        <v-chip-group
          v-model="sortBy"
          active-class="secondary"
          v-on:change="routeSelectorChange()"
        >
          <v-chip
            v-for="(item, index) in sortByItems"
            :key="index"
            :value="item.value"
            color="transparent"
          >
            <v-icon left small>
              {{ item.icon }}
            </v-icon>
            {{ item.label }}
          </v-chip>
        </v-chip-group>

        <!-- @@@@@@@@@@@@@   time, controlled by timeDisplay   @@@@@@@@@@@@@  -->
          <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-3" color="">
            <template v-slot:activator="{ on, attrs }">
              <v-chip :class="timeDisplay" v-on="on" v-bind="attrs" color="">
                {{ time.label }}
                <v-icon right >
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-list class="cursor">
              <v-list-item-group v-model="time" v-on:change="routeSelectorChange()">
                <v-list-item v-for="(item, index) in timeItems" :key="index" :value="item">
                  <v-list-item-title style="font-size: 14px;">{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        <v-spacer></v-spacer>
        <v-btn
          v-if="this.contentRoute !== 'santa'"
          fab
          light
          small
          color="white"
          @click="setVDialog(true)"
          class="submit-display"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-menu v-else bottom :offset-y="true" transition="slide-y-transition" class="mr-0" color="">
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="chip-overflow mr-0 cat-display" v-on="on" v-bind="attrs" :color="category.color">
              <span>
                {{ category.name }}
              </span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>

          <v-list class="cursor">
            <v-list-item-group v-model="categoryId" mandatory v-on:change="fullReload()">
              <v-list-item v-for="(cat, index) in sortCategories" :key="index" :value="cat.category_id">
                <v-list-item-title style="font-size: 14px;">{{ cat.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar>
      <!-- ref="sidebar" id="sidebar" -->
      <div style="max-height: calc(100% - 50px); overflow-y: auto;">
        <v-list style="background-color: #0e0e10;">
          <!-- <v-list-item v-for="(item, index) in posts" :key="index"> -->
            <v-list-item-group mandatory v-model="postIndex">
                <PlayerVideoCard
                  v-for="(item, index) in posts"
                  :key="index"
                  :value="index"
                  :thumbnailUrl="item.thumbnailUrl"
                  :title="item.title"
                  :submitterName1="item.submitterName1"
                  :submitTimestamp="item.submitTimestamp"
                  :viewCount="item.view_count"
                  :badgeIcon="item.badge_icon"
                  :badgeColor="item.badge_color"
                  :badgeStyle="item.badge_style"
                  :badgeText="item.badge_text"
                  :creatorName="item.creatorName"
                  :channelId="item.channel_id"
                  :contentType="item.content_type"
                  :category="item.category"
                  :cat_color="item.cat_color"
                  :username="item.user_name"
                  :upvotes="item.upvotes"
                  :contentRoute="contentRoute"
                  :index="index"
                  :isMod="enableModView"
                  :duration="item.duration"
                >
                </PlayerVideoCard>
                <!-- :id="'clip' + index" -->
            </v-list-item-group>
          <!-- </v-list-item> -->
        </v-list>
        <v-btn :disabled="loadMoreDisabled" class="mb-3" style="width: 100%;" @click="fetchPosts">
          Load More
          <v-icon right style="bottom: 1px;">
            mdi-chevron-double-down
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import VueRouter from 'vue-router'
import getContentService from '../utils/ContentService'
import CommunityService from '../services/CommunityService'
import UserService from '../services/UserService'
import PlayerVideoCard from '../components/PlayerVideoCard'
import LoginCard from '../components/LoginCard.vue'
import SubmissionCard from '../components/SubmissionCard.vue'
import CommunityRedirectCard from '../components/CommunityRedirectCard'
import { sortByItems, timeItems } from '../utils/constants'
import { getCreatorInfo, getFormattedViews, getFormattedSubmitDate } from '../utils/formatter'

import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'PlayerView',
  components: { PlayerVideoCard, LoginCard, SubmissionCard, CommunityRedirectCard },
  data () {
    return {
      data: 1,
      posts: [],
      postIndex: 0,
      // todo, make date picker a component and use it here as well
      fromDate: null,
      toDate: null,
      playerHeight: 400,
      offset: 0,
      parentUrl: process.env.VUE_APP_UI_BASE_URL,
      sortByItems: sortByItems,
      timeItems: timeItems,
      sortBy: this.sortByInit,
      timeData: this.timePropInit,
      includeWatched: null,
      enableModView: false,
      deleteCount: 0,
      creatorFilter: null,
      community: {
        game_id: null,
        subpages: []
      },
      loadMoreDisabled: false,
      playerWidth: '100%',
      creatorList: ['All Creators'],
      categoryId: null
      // category: this.categoryInit
    }
  },

  methods: {

    ...mapMutations(['setErrorNotification', 'setSuccessNotification', 'setBanNotification', 'setEmoteNotification', 'setVDialog']),

    // postSelected: function () {
    //   window.scrollTo({ top: 0 })
    // },

    fetchPosts: async function () {
      var timeParam
      if (this.sortBy === 'top') {
        timeParam = this.time.query
      }
      var res
      if (this.isUserPlayer) {
        res = await this.service.getUserContent(this.sortBy, this.community.userId, this.offset, timeParam, this.duration, null, this.isFavorites)
      } else {
        res = await this.service.getContent(this.sortBy, this.categoryId, this.offset, timeParam, this.community.game_id, null, null, null, this.includeWatched, this.creatorFilter, this.subpageData.order, this.fromDate, this.toDate)
      }
      this.posts = this.posts.concat(res.data)
      this.posts = this.posts.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.postId === value.postId
        ))
      )
      this.offset += res.data.length
      if (res.data.length === 0) {
        this.loadMoreDisabled = true
      }
    },

    reloadPosts: async function () {
      var timeParam
      if (this.sortBy === 'top') {
        timeParam = this.time.query
      }
      var res
      if (this.isUserPlayer) {
        res = await this.service.getUserContent(this.sortBy, this.community.userId, this.offset, timeParam, this.duration, null, this.isFavorites)
      } else {
        res = await this.service.getContent(this.sortBy, this.categoryId, this.offset, timeParam, this.community.game_id, null, null, null, this.includeWatched, this.creatorFilter, this.subpageData.order, this.fromDate, this.toDate)
      }
      this.posts = res.data
      this.offset += res.data.length
      if (res.data.length === 0) {
        this.loadMoreDisabled = true
      }
    },

    fullReload: async function () {
      this.offset = 0
      this.postIndex = 0
      await this.reloadPosts()
      const currClip = document.getElementById('clip0')
      if (currClip) {
        currClip.scrollIntoView()
      }
    },

    uploadVideo: async function (video) {
      try {
        await this.service.uploadContent(video.url, video.category_id, this.community.game_id, video.sec_cat_id, video.title, video.context, null)
        this.sortBy = 'new'
        this.setVDialog(false)

        this.routeSelectorChange()
        this.setSuccessNotification({ trigger: true })
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setVDialog(false)
          this.setBanNotification(true)
        } else if (error.response && error.response.status === 409) {
          this.setErrorNotification({ trigger: true, message: 'This has already been submitted ):' })
        } else if (error.response && error.response.status === 414) {
          this.setErrorNotification({ trigger: true, message: 'This looks a little long to be a clip, maybe submit it under videos' })
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    handleResize: function (event) {
      var height = this.$refs.player.clientWidth * 0.55952381 // standard dimensions are 168 x 94, keeping the aspect ratio
      if (height > 650) {
        this.playerHeight = 650
      } else {
        this.playerHeight = height
      }
    },

    upvote: async function () {
      try {
        if (this.userDetails) {
          // nah, init_upvoted is a number and need to base upvoted / downvoted on that
          if (this.selectedPost.init_upvoted > 0) {
            this.selectedPostLocalVotes -= this.selectedPost.init_upvoted
            this.selectedPost.init_upvoted = null
            await this.service.upvote(this.selectedPost.postId)
          } else if (this.selectedPost.init_upvoted < 0) {
            // this is a double negative
            this.selectedPostLocalVotes -= this.selectedPost.init_upvoted
            if (this.enableModView) {
              this.selectedPostLocalVotes += 5
              this.selectedPost.init_upvoted = 5
              await this.service.superUpvote(this.selectedPost.postId, this.community.game_id)
            } else {
              this.selectedPostLocalVotes += 1
              this.selectedPost.init_upvoted = 1
              await this.service.upvote(this.selectedPost.postId)
            }
          } else {
            if (this.enableModView) {
              this.selectedPostLocalVotes += 5
              this.selectedPost.init_upvoted = 5
              await this.service.superUpvote(this.selectedPost.postId, this.community.game_id)
            } else {
              this.selectedPostLocalVotes += 1
              this.selectedPost.init_upvoted = 1
              await this.service.upvote(this.selectedPost.postId)
            }
          }
        } else {
          this.setVDialog(true)
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setBanNotification(true)
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    downvote: async function () {
      try {
        if (this.userDetails) {
          // await this.service.downvote(this.selectedPost.postId)
          // nah, init_upvoted is a number and need to base upvoted / downvoted on that
          if (this.selectedPost.init_upvoted > 0) {
            this.selectedPostLocalVotes -= this.selectedPost.init_upvoted
            if (this.enableModView) {
              this.selectedPostLocalVotes -= 2
              this.selectedPost.init_upvoted = -2
            } else {
              this.selectedPostLocalVotes--
              this.selectedPost.init_upvoted = -1
            }
          } else if (this.selectedPost.init_upvoted < 0) {
            // this is a double negative
            this.selectedPostLocalVotes -= this.selectedPost.init_upvoted
            this.selectedPost.init_upvoted = null
          } else {
            if (this.enableModView) {
              this.selectedPostLocalVotes -= 2
              this.selectedPost.init_upvoted = -2
            } else {
              this.selectedPostLocalVotes--
              this.selectedPost.init_upvoted = -1
            }
          }
        } else {
          this.setVDialog(true)
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setBanNotification(true)
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    routeSelectorChange: async function () {
      this.loadMoreDisabled = false
      var routeConfig = '/'
      if (this.gameName) {
        routeConfig = routeConfig + this.gameName + '/'
      } else if (this.isUserPlayer) {
        routeConfig = routeConfig + 'user/' + this.username + '/player/' + this.contentRoute + '/' + this.sortBy
      } else {
        routeConfig = routeConfig + 'player/' + this.contentRoute + '/' + this.sortBy
      }

      if (this.sortBy === 'top') {
        routeConfig = routeConfig + '?t=' + this.time.query
      }

      this.$router.push(routeConfig).catch(async failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          await this.fullReload()
        }
      })
    },

    favoriteClicked: async function () {
      if (this.userDetails) {
        await this.service.favoriteContent(this.selectedPost.postId)
        this.selectedPost.init_favorited = !this.selectedPost.init_favorited
      } else {
        this.setVDialog(true)
      }
    },

    returnToMainPage: function () {
      var routeConfig = '/'
      if (this.gameName) {
        routeConfig = routeConfig + this.gameName + '/' + this.contentRoute + '/' + this.sortBy
      } else if (this.isUserPlayer) {
        routeConfig = routeConfig + 'user/' + this.username + '/' + this.contentRoute + '/' + this.sortBy
      } else {
        routeConfig = routeConfig + this.contentRoute + '/' + this.sortBy
      }
      if (this.sortBy === 'top') {
        routeConfig = routeConfig + '?t=' + this.time.query
      }
      this.$router.push(routeConfig)
    },

    markWatched: async function (hide, value) {
      try {
        await this.service.markWatched(this.selectedPost.postId, this.community.game_id, hide, value)
        if (!this.includeWatched) {
          this.posts.splice(this.postIndex, 1)
          this.deleteCount++
          this.offset--
        } else {
          this.posts[this.postIndex].watched = !this.posts[this.postIndex].watched
        }
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    deleteContent: async function () {
      try {
        await this.service.deleteContent(this.selectedPost.postId)
        this.posts.splice(this.postIndex, 1)
        this.deleteCount++
        this.offset--
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    videoEnded: function () {
      if (this.posts.length > this.postIndex + 1) {
        this.postIndex++
      }
    }
  },

  computed: {

    ...mapGetters(['userDetails']),

    service: function () {
      // favorites tech debt
      if (this.subpageData.type && this.subpageData.type < 3) {
        return getContentService(this.subpageData.type)
      }
      return getContentService(1)
    },

    // favorites tech debt
    isFavorites: function () {
      if (this.isUserPlayer && this.subpageData.type === 4) {
        return true
      }
      return undefined
    },

    // this is just used for submissions I believe
    category: function () {
      if (this.subpageData && this.subpageData.categories && this.categoryId) {
        for (const cat of this.subpageData.categories) {
          if (cat.category_id === this.categoryId) {
            return cat
          }
        }
      }

      return this.categoryInit
    },

    selectedPost: function () {
      try {
        return this.posts[this.postIndex] || {
          title: ''
        }
      } catch (error) {
        return {
          title: 'Error'
        }
      }
    },

    selectedPostCreatorInfo: function () {
      return getCreatorInfo(this.selectedPost.content_type, this.selectedPost.channel_id)
    },

    selectedPostViews: function () {
      return getFormattedViews(this.selectedPost.view_count)
    },

    viewSuffix: function () {
      if (this.selectedPostViews === 1) {
        return 'view'
      } else {
        return 'views'
      }
    },

    selectedPostSubmitDate: function () {
      return getFormattedSubmitDate(this.selectedPost.submitTimestamp)
    },

    selectedPostLocalVotes: {
      get () {
        return parseInt(this.selectedPost.upvotes) || 0
      },

      set (value) {
        this.selectedPost.upvotes = value.toString()
      }
    },

    vDialog: {
      get () {
        return this.$store.state.twitterAuth.vDialog
      },

      set (value) {
        // this.$store.commit('setVDialog', value)
        this.setVDialog(value)
      }
    },

    isMod: function () {
      if (this.userDetails && this.community && this.userDetails.mod_comms) {
        for (const id of this.userDetails.mod_comms) {
          if (id === this.community.game_id) {
            return true
          }
        }
      }
      return false
    },

    upvoted: function () {
      if (this.selectedPost.init_upvoted > 0) {
        return true
      } else {
        return false
      }
    },

    downvoted: function () {
      if (this.selectedPost.init_upvoted < 0) {
        return true
      } else {
        return false
      }
    },

    upvoteColor: function () {
      if (this.upvoted) {
        return 'green'
      } else {
        return 'gray'
      }
    },

    downvoteColor: function () {
      if (this.downvoted) {
        return 'red'
      } else {
        return 'gray'
      }
    },

    // slug: function () {
    //   try {
    //     var matches = this.selectedPost.url.match(/^.*(?:clips.twitch.tv|twitch.tv\/.*\/clip)\/([^\?]*)(?:\?.*)?$/) // eslint-disable-no-useless-escape
    //     return matches[1]
    //   } catch (error) {
    //     return ''
    //   }
    // },

    // LOLLLLLLL
    embedSrc: function () {
      try {
        if (this.selectedPost.content_type === 1) {
          const matches = this.selectedPost.url.match(/^.*(?:clips.twitch.tv|twitch.tv\/.*\/clip)\/([^\?]*)(?:\?.*)?$/) // eslint-disable-line
          return 'https://clips.twitch.tv/embed?clip=' + matches[1] + '&parent=' + this.parentUrl + '&autoplay=true&muted=false'
        } else if (this.selectedPost.content_type === 4) {
          const matches = this.selectedPost.url.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/) // eslint-disable-line
          if (matches && matches.length >= 3) {
            return matches[2]
          } else {
            return '/no_clips.html'
          }
        } else if (this.selectedPost.embed_url) {
          if (this.selectedPost.embed_url.includes('?')) {
            return this.selectedPost.embed_url + '&autoplay=1'
          } else {
            return this.selectedPost.embed_url + '?autoplay=1'
          }
        } else {
          return '/no_clips.html'
        }
      } catch (error) {
        return '/no_clips.html'
      }
    },

    timeDisplay: function () {
      return this.sortBy === 'top' ? 'mr-3 mr-sm-6' : 'd-none'
    },

    time: {
      get () {
        return this.timeItems[this.timeData]
      },
      set (newObj) {
        this.timeData = newObj.query
        return newObj
      }
    },

    subpageData: function () {
      var ret = {
        order: null,
        type: null
      }
      if (this.gameName || this.isUserPlayer) {
        var contentRoute = this.contentRoute
        this.community.subpages.forEach(function (subpage) {
          if (subpage.route === contentRoute) {
            ret = subpage
          }
        })
      }
      return ret
    },

    sortCategories: function () {
      if (this.subpageData && this.subpageData.categories) {
        return [this.categoryInit, ...this.subpageData.categories]
      }
      return []
    },

    submitCategories: function () {
      return this.sortCategories.filter(cat => !cat.sortOnly)
    },

    player: function () {
      return this.$refs.ytplayer.player
    }

  },

  watch: {
    async $route (to, from) {
      await this.fullReload()
    },

    deleteCount: async function (value) {
      if (value > 20) {
        this.deleteCount = 0
        await this.fetchPosts()
      }
    }
  },

  created: async function () {
    this.community = this.$store.state.twitterAuth.community
    this.posts = this.$store.state.twitterAuth.posts
    this.postIndex = this.$store.state.twitterAuth.postIndex
    this.enableModView = this.$store.state.twitterAuth.modEnabled
    this.offset = this.$store.state.twitterAuth.offset
    this.creatorFilter = this.$store.state.twitterAuth.creatorFilter
    this.creatorList = this.$store.state.twitterAuth.creatorList
    this.categoryId = this.$store.state.twitterAuth.categoryId

    var resp
    if (this.gameName && this.community.game_id === null) {
      try {
        resp = await CommunityService.getCommunity(this.gameName)
      } catch (error) {
        if (error.response.status === 404) {
          this.$router.push({ name: 'sadge' })
        }
      }
    } else if (this.isUserPlayer) {
      try {
        resp = await UserService.getSpecificUser(this.username)
      } catch (error) {
        if (error.response.status === 404) {
          this.$router.push({ name: 'sadge' })
        }
      }
    }

    if (resp) {
      this.community = resp.data
    }
    if (this.posts.length === 0) {
      // const res = await this.service.getContent(this.sortBy, this.category.category_id, this.offset, timeParam, this.community.game_id, this.duration, null, this.secCategory, this.subpageData.data.is_watched_tab)
      await this.fetchPosts()
    }
  },

  mounted: function () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    const currClip = document.getElementById('clip' + this.postIndex)
    if (currClip) {
      currClip.scrollIntoView({
        block: 'center',
        inline: 'nearest'
      })
    }
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },

  props: {
    gameName: String,
    isUserPlayer: {
      type: Boolean,
      default: false
    },
    username: String,
    contentRoute: {
      type: String,
      default: 'clips'
    },
    sortByInit: {
      type: String,
      default: 'hot'
    },
    timePropInit: {
      type: String,
      default: 'week'
    },
    categoryInit: {
      type: Object,
      default: function () {
        return {
          name: 'All',
          game_id: 1,
          category_id: null,
          color: null,
          sortOnly: true
        }
      }
    }
  }
}
</script>

<style scoped>

::-webkit-scrollbar {
    width: 16px;
    padding: 4px;
  }

  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 10px #0e0e10; */
    background-color: #0e0e10;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid #0e0e10;
    border-radius: 9999px;
    background-color: #aaa;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #7a7a7a;
  }

  /* 1649 */
  @media only screen and (max-width: 1649px) {
    .submit-display {
      display: none;
    }
  }

    @media only screen and (max-width: 1551px) {
    .cat-display {
      display: none;
    }
  }

  @media only screen and (min-width: 1650px) {
    .list-flex {
      flex: 1 1 25%;
    }
  }

  @media only screen and (max-width: 1649px) {
    .list-flex2 {
      flex: 1 1 33%;
    }
  }

  .user-text:hover {
    color: white;
    font-weight: bold;
  }

  .creator-link {
    text-decoration: none;
    color: inherit;
  }

  .creator-background {
    border-radius: 50px;
    padding: 5px 8px 5px 8px;
  }

  .submit-by {
    text-decoration: none;
    color: white;
  }

  .custom-title {
    line-height: 1.4em !important;
    height: 2.8em;
  }
</style>
