<template>
  <div v-infinite-scroll="getVideos" infinite-scroll-immediate-check="false" infinite-scroll-disabled="reloadBusy">
    <v-img :src="community.banner_url" min-height="64" max-height="200">
    </v-img>

    <!-- ### SUBMISSION OR LOGIN ### -->
    <v-dialog v-model="vDialog" width="400px">
      <SubmissionCard v-if="userDetails"
        v-on:upload-video="uploadVideo"
        :titleLabel="submitTitleLabel"
        :inputLabel="submitInputLabel"
        :userTitleLabel="submitUserTitleLabel"
        :errorLabel="submitErrorLabel"
        :categoryItems="community.categories[this.contentType]"
        :secCategoryItems="community.secondaryCategories"
        :secCatOptional="community.sec_cat_optional"
        :secCatLabel="community.sec_cat_label"
        :validUrlPattern="validUrlPattern"
        :secCatEnabled="community[`sec_cat_enabled_${contentType}`]"
      >
      </SubmissionCard>
      <LoginCard v-else>
      </LoginCard>
    </v-dialog>

    <!-- ### COMMUNITY CARD ### -->
    <v-card class="mb-1">
      <v-container class="py-0" style="max-width: 1100px;">
        <v-card-title class="pb-1 pl-0">
          <v-avatar class="mr-4 mt-n1" size="80" style="border-style: solid; border-width: 2px;">
            <img :src="community.sq_avi_url" :alt="community.name">
          </v-avatar>
          <h2 class="mt-n1">{{ community.name }}</h2>
        </v-card-title>

        <v-card-actions class="py-0 pl-0 mt-3">
          <v-tabs v-model="tabModel" class=""  color="white" active-class="white--text">
            <v-tab v-for="(item, index) in tabItems" :to="'/' + gameName + '/' + item.route" :key="index" :value="index">{{ item.label }}</v-tab>
          </v-tabs>
          <v-spacer></v-spacer>
          <!-- <v-btn v-if="community.game_id === 2" @click.stop="messageDialog = !messageDialog" flat color="deep-purple" style="font-size: 12px;">
            Updates
          </v-btn> -->
          <v-btn outlined :to="'/leaderboard/' + community.routeParam" color="" class="mb-1">
            <v-icon left>
              mdi-podium
            </v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">
              Leaderboard
            </span>
          </v-btn>
          <v-switch
            v-if="isMod"
            class="my-auto ml-3"
            hide-details
            v-model="enableModView"
            label="Mod"
          >
          </v-switch>
        </v-card-actions>
      </v-container>
      </v-card>
    <v-container :class="community[`sec_cat_enabled_${contentType}`] ? 'pt-0' : ''" style="max-width: 1100px;">

      <v-chip-group
        v-if="community[`sec_cat_enabled_${contentType}`]"
        v-model="secCategory"
        mandatory
        active-class="secondary"
        class="my-2"
        @change="categoryChange"
      >
        <v-chip
          v-for="cat in secondaryCategories"
          :key="cat.category_id"
          :value="cat.category_id"
          style="border-style: solid; border-color: #414141; border-width: 1px;"
          color="#272727"
        >
          {{ cat.name }}
        </v-chip>
      </v-chip-group>

      <!-- ### CONTENT TOOLBAR ### -->
      <v-toolbar outlined rounded dense color="">

          <!-- @@@@@@@@@@@@@   DESKTOP   @@@@@@@@@@@@@  -->
          <v-chip-group
            class="mr-4"
            v-if="!$vuetify.breakpoint.xs"
            mandatory
            active-class="secondary"
            v-model="sortBy"
            v-on:change="routeSelectorChange()"
          >
            <v-chip
              color="transparent"
              v-for="(item, index) in sortByItems"
              :key="index"
              :value="item.value"
            >
              <v-icon left small>
                {{ item.icon }}
              </v-icon>
              {{ item.label }}
            </v-chip>
          </v-chip-group>

          <!-- @@@@@@@@@@@@@   MOBILE   @@@@@@@@@@@@@  -->

          <!-- @@@@@@@@@@@@@   sortBy   @@@@@@@@@@@@@  -->
          <v-menu v-else bottom :offset-y="true" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs"  class="mr-3" color="">
                <v-icon left small>
                  {{ sortByItems[sortBy].icon }}
                </v-icon>
                {{ sortByItems[sortBy].label }}
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-list class="cursor">
              <v-list-item-group v-model="sortBy" mandatory v-on:change="routeSelectorChange()">
                <v-list-item v-for="(sort, index) in sortByItems" :key="index" :value="sort.value">
                  <v-icon left small>
                    {{ sort.icon }}
                  </v-icon>
                  <v-list-item-title style="font-size: 14px;">{{ sort.label }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <!-- @@@@@@@@@@@@@   time, controlled by timeDisplay   @@@@@@@@@@@@@  -->
          <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-1" color="">
            <template v-slot:activator="{ on, attrs }">
              <v-chip :class="timeDisplay" v-on="on" v-bind="attrs" color="">
                {{ time.label }}
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-list class="cursor">
              <v-list-item-group v-model="time" mandatory v-on:change="routeSelectorChange()">
                <v-list-item v-for="(item, index) in timeItems" :key="index" :value="item">
                  <v-list-item-title style="font-size: 14px;">{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <!-- @@@@@@@@@@@@@   category   @@@@@@@@@@@@@  -->
          <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-1" color="">
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="chip-overflow mr-4" v-on="on" v-bind="attrs" >
                <span v-if="shortCategory">
                  {{ category.name.substr(0, 4) + '...' }}
                </span>
                <span v-else>
                  {{ category.name }}
                </span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-list class="cursor">
              <v-list-item-group v-model="category" mandatory v-on:change="categoryChange()">
                <v-list-item v-for="(cat, index) in categories" :key="index" :value="cat">
                  <v-list-item-title style="font-size: 14px;">{{ cat.name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

        <v-spacer></v-spacer>

        <v-btn
          fab
          light
          small
          color="white"
          @click="setVDialog(true)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-col class="ma-0 pa-0">
        <v-select
          class="mt-4"
          outlined
          hide-details
          dense
          placeholder="Filter by Duration..."
          :items="durationItems"
          v-model="duration"
          @change="categoryChange"
          :menu-props="{ 'offset-y': true, 'transition': 'slide-y-transition' }"
          width="170px"
          style="width: 170px; font-size: 12px !important; font-weight: bold;"
          v-show="contentType === 'videos'"
        >
        </v-select>
      </v-col>

      <!-- ### CONTENT GRID ### -->
      <!-- contentRoute should be bound to the value of the v-selector thing for clips / videos-->
      <v-row v-if="!errorState && posts.length !== 0" wrap align-content-start class="mt-1">
        <v-col v-for="(post, index) in posts" :key=index class="three-cols-b two-cols-b one-col-b">
          <VideoCard
            v-on:content-deleted="contentDeleted(index, post.postId)"
            v-on:mark-watched="markWatched(index, post.postId, false)"
            v-on:mark-hidden="markWatched(index, post.postId, true)"
            v-on:primary-chip-selected="primaryChipSelected(post.category_id)"
            v-on:secondary-chip-selected="secondaryChipSelected(post.sec_cat_id)"
            v-on:content-vote="contentVote(index, ...arguments)"
            v-bind="post"
            :primaryChipColor="post.cat_color"
            :chipLabel="post.category"
            :dbUpvote="service.upvote"
            :dbDownvote="service.downvote"
            :isMod="enableModView"
            :contentRoute="contentType"
            :duration="post.duration"
            :submitterId="post.userId"
            :submitterName1="post.submitterName1"
            :viewCount="post.view_count"
            :upvotes="post.upvotes"
            :init_upvoted="post.init_upvoted"
            :user_name="post.user_name"
            :secondaryChipLabel="post.sec_cat"
            :secondaryChipColor="post.sec_cat_color"
            :content_type="post.content_type"
            :playerSwitch="true"
          >
          </VideoCard>
        </v-col>
      </v-row>
      <span v-else>
        <MainViewError v-if="errorState" :message="'something went wrong getting ' + contentType + ' ... please try again later'"></MainViewError>
        <MainViewError v-else message='be the first to submit with the + button above!'></MainViewError>
      </span>

    </v-container>
  </div>
</template>

<script>
// break the above out to another component?
import SubmissionCard from '../components/SubmissionCard'
import LoginCard from '../components/LoginCard'
import VideoCard from '../components/VideoCard'
import MainViewError from '../components/MainViewError'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VueRouter from 'vue-router'
import urlUtilsMixin from '../utils/urlUtils'

export default {
  name: 'GameView',

  components: { VideoCard, MainViewError, SubmissionCard, LoginCard },

  mixins: [urlUtilsMixin],

  data () {
    return {
      posts: [],
      tabModel: 0,
      enableModView: false,
      sortByItems: {
        hot: {
          label: 'Hot',
          value: 'hot',
          icon: 'mdi-fire'
        },
        new: {
          label: 'New',
          value: 'new',
          icon: 'mdi-decagram-outline'
        },
        top: {
          label: 'Top',
          value: 'top',
          icon: 'mdi-transfer-up'
        }
      },
      timeItems: {
        day: {
          label: 'Today',
          query: 'day'
        },
        week: {
          label: 'Week',
          query: 'week'
        },
        month: {
          label: 'Month',
          query: 'month'
        },
        year: {
          label: 'Year',
          query: 'year'
        },
        all: {
          label: 'All Time',
          query: 'all'
        }
      },
      durationItems: [
        {
          text: 'All Durations',
          value: null
        },
        {
          text: 'Less than 10 mins',
          value: 10
        },
        {
          text: '10 - 30 mins',
          value: 30
        },
        {
          text: '30 mins - 1 hr',
          value: 60
        },
        {
          text: 'More than 1 hr',
          value: 61
        }
      ],
      duration: undefined,
      offset: 0,

      // getVideos error
      errorState: 0,
      sortBy: this.sortByInit,

      // should set up category like secCategory rather than the init object
      category: this.categoryInit,
      secCategory: null,
      timeDataInit: this.timeInit,
      reloadBusy: true
    }
  },

  methods: {

    ...mapActions(['triggerErrorNotification']),

    ...mapMutations(['setErrorNotification', 'setSuccessNotification', 'setBanNotification', 'setVDialog']),

    uploadVideo: async function (video) {
      try {
        await this.service.uploadContent(video.url, video.category_id, this.gameName, video.sec_cat_id, video.title, video.context, null)
        this.sortBy = 'new'
        // set to category of uploaded content
        // this.category = video.category
        this.category = this.categories[0]
        this.secCategory = null
        this.setVDialog(false)

        this.routeSelectorChange()
        this.setSuccessNotification({ trigger: true })
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setVDialog(false)
          this.setBanNotification(true)
        } else if (error.response && error.response.status === 409) {
          this.setErrorNotification({ trigger: true, message: 'This has already been submitted ):' })
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    getVideos: async function () {
      try {
        var timeParam = null

        if (this.sortBy === 'top') {
          timeParam = this.time.query
        }
        const res = await this.service.getContent(this.sortBy, this.category.category_id, this.offset, timeParam, this.gameName, this.duration, null, this.secCategory, undefined, null, null)
        this.posts = this.posts.concat(res.data)
        this.offset += res.data.length
        if (this.reloadBusy) {
          this.reloadBusy = false
        }
      } catch (err) {
        this.errorState = 1
      }
    },

    // push the route on change, watcher on route causes the reload. On duplicated nav failure manually reload
    routeSelectorChange: async function () {
      var routeConfig = '/' + this.gameName + '/' + this.contentType + '/' + this.sortBy

      if (this.sortBy === 'top') {
        routeConfig = routeConfig + '?t=' + this.time.query
      }

      this.$router.push(routeConfig).catch(async failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // if nav is duplicated, why reload? maybe just catch error and do nothing?
          this.offset = 0
          this.posts = []
          this.reloadBusy = true
          await this.getVideos() // await?
        }
      })
    },

    categoryChange: function () {
      this.offset = 0
      this.posts = []
      this.reloadBusy = true
      this.getVideos() // await?
    },

    primaryChipSelected: function (categoryId) {
      // there will never be thaat many cats... just find in the array
      if (categoryId !== this.category.category_id) {
        this.category = this.categories.find(el => el.category_id === categoryId)
        this.categoryChange()
      }
    },

    secondaryChipSelected: function (categoryId) {
      // there will never be thaat many cats... just find in the array
      if (categoryId !== this.secCategory) {
        this.secCategory = this.community.secondaryCategories.find(el => el.category_id === categoryId).category_id
        this.categoryChange()
      }
    },

    // on content-deleted emit event from VideoCard
    contentDeleted: async function (deleteIndex, postId) {
      try {
        await this.service.deleteContent(postId)
        this.posts.splice(deleteIndex, 1)
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    markWatched: async function (index, postId, hide) {
      try {
        await this.service.markWatched(postId, this.community.game_id, hide)
        this.posts.splice(index, 1)
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    contentVote: function (index, upvotes, upvoted, downvoted) {
      if (upvoted) {
        this.posts[index].init_upvoted = 1
      } else if (downvoted) {
        this.posts[index].init_upvoted = -1
      } else {
        this.posts[index].init_upvoted = null
      }
      this.posts[index].upvotes = upvotes.toString()
    }
  },

  props: {
    // categoryInit: {
    //   type: String,
    //   default: 'All'
    // },
    sortByInit: {
      type: String,
      default: 'hot'
    },
    timeInit: {
      type: String,
      default: 'week'
    },
    categoryItems: {
      type: Array,
      default: () => []
    },
    categoryInit: {
      type: Object,
      default: function () {
        return {
          name: 'All Categories',
          game_id: 1,
          category_id: null,
          color: 'grey'
        }
      }
    },
    secCatInit: {
      type: Object,
      default: function () {
        return {
          name: 'All',
          game_id: 1,
          category_id: null,
          color: 'grey'
        }
      }
    },
    tabItems: {
      type: Array,
      default: () => {
        return [
          {
            label: 'Videos',
            route: 'videos'
          },
          {
            label: 'Clips',
            route: 'clips'
          }
        ]
      }
    },
    gameName: String,
    errorInit: Boolean,
    service: Object,
    contentType: {
      type: String,
      default: 'videos'
    },
    submitTitleLabel: String,
    submitUserTitleLabel: String,
    submitInputLabel: String,
    submitErrorLabel: String,
    validUrlPattern: Array,
    isWatchedTab: Boolean
  },

  computed: {
    ...mapGetters(['userDetails', 'getCommunity']),

    // calculated when game (route) changes
    community: function () {
      return this.$store.getters.getCommunity(this.gameName)
    },

    // caluculated every time contentType changes
    categories: function () {
      return [this.categoryInit, ...this.community.categories[this.contentType]]
    },

    secondaryCategories: function () {
      return [this.secCatInit, ...this.community.secondaryCategories]
    },

    isMod: function () {
      if (this.userDetails && this.community && this.userDetails.mod_comms) {
        for (const id of this.userDetails.mod_comms) {
          if (id === this.community.game_id) {
            return true
          }
        }
      }
      return false
    },

    vDialog: {
      get () {
        return this.$store.state.twitterAuth.vDialog
      },

      set (value) {
        // this.$store.commit('setVDialog', value)
        this.setVDialog(value)
      }
    },

    errorNotification: {
      get () {
        return this.$store.state.twitterAuth.errorNotification
      },

      set (value) {
        this.setErrorNotification({ trigger: value })
      }
    },

    successNotification: {
      get () {
        return this.$store.state.twitterAuth.successNotification
      },

      set (value) {
        this.setSuccessNotification({ trigger: value })
      }
    },

    // hmmm
    shortCategory: function () {
      if (this.$vuetify.breakpoint.xs && this.sortBy === 'top' && this.category.name.length > 4) {
        return true
      } else {
        return false
      }
    },

    timeDisplay: function () {
      return this.sortBy === 'top' ? 'ml-n1 mr-3 ml-sm-n4 mr-sm-6' : 'd-none'
    },

    time: {
      get () {
        return this.timeItems[this.timeDataInit]
      },
      set (newObj) {
        this.timeDataInit = newObj.query
        return newObj
      }
    }
  },

  // beforeMount was here before, why?
  created: async function () {
    if (this.errorInit) {
      this.setErrorNotification({ trigger: this.errorInit })
    }
    this.reloadBusy = true
    this.getVideos()
  },

  watch: {
    $route (to, from) {
      if (to.params.sortByUrl) {
        this.sortBy = to.params.sortByUrl
      }

      // can optimize this
      if (to.params.content !== from.params.content) {
        this.category = this.categoryInit
      }
      if (to.params.content === 'clips') {
        this.duration = undefined
      }

      this.offset = 0
      this.posts = []
      this.reloadBusy = true
      this.getVideos()
    }
  }
}
</script>

<style scoped>

  /* used to be 1099, why? */
  @media only screen and (min-width: 991px) {
    .three-cols-b {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 990px) {
    .two-cols-b {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }
  @media only screen and (max-width: 600px) {
    .one-col-b {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @media only screen and (min-width: 1600px) and (max-width: 2000px) {
    .five-cols {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }

    .selector {
      width: 40%;
    }

  }

  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .four-cols {
      width: 25%;
      max-width: 25%;
      flex-basis: 25%;
    }
  }

  @media only screen and (min-width: 990px) and (max-width: 1301px) {
    .three-cols {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 989px) {
    .two-cols {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }

  @media only screen and (max-width: 599px) {
    .one-col {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
</style>
