<template>
  <v-card outlined>
    <v-card-title class="justify-center">Add {{ contentType }} to a community</v-card-title>
    <v-card-text class="text-center">
      Go to a community's page to add {{ contentType }}!
    </v-card-text>

    <v-btn @click="communityRedirect" rounded class="ma-auto" style="display: flex;" color="white" light>
      View Communities
    </v-btn>

    <v-card-actions class="pt-6">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="setVDialog(false)"
      >
        Close
      </v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'CommunityRedirectCard',
  data () {
    return {
    }
  },

  methods: {
    ...mapMutations(['setVDialog']),

    communityRedirect: function () {
      this.setVDialog(false)
      this.$router.push('/communities')
    }
  },

  props: {
    contentType: {
      type: String,
      default: 'video'
    }
  }

}
</script>

<style>

</style>
