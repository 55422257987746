<template>
  <div class="center">
    <p class="text-center text-body-1 font-weight-bold mb-8 mt-8"> {{ message }}</p>
    <v-img v-if="image" class="mx-auto mb-8" width="150" height="150" :src="image"></v-img>
    <v-btn v-if="refresh" class="mt-0" @click="reload">Refresh</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    image: String,
    refresh: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    reload: function () {
      location.reload()
    }
  }
}
</script>

<style>
  .center {
    text-align: center;
  }
</style>
