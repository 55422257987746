<template>
  <v-card outlined>
    <v-card-title class="justify-center">Delete Account</v-card-title>
    <v-card-text class="text-center">
      Are you sure? Deleting your account will delete all user data from SmashScene, including any posts you've made. This is not reversible.
    </v-card-text>
    <v-btn @click="deleteConfirm" rounded class="ma-auto mb-5" style="display: flex;" color="red">
      Yes, I'm sure
    </v-btn>
    <v-btn @click="setDeleteRequested(false)" outlined rounded class="ma-auto" style="display: flex;">
      Nah
    </v-btn>
    <v-card-actions class="pt-5">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="setDeleteRequested(false)"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'DeleteCard',

  methods: {
    ...mapActions(['deleteAccount']),

    ...mapMutations(['setDeleteRequested', 'setSuccessNotification', 'setErrorNotification']),

    deleteConfirm: async function () {
      try {
        await this.deleteAccount()
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    }
  }
}
</script>

<style>

</style>
