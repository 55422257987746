import Api from './Api'

export default {
  getCommunity (name) {
    return Api().get('community',
      {
        withCredentials: true,
        params: {
          name: name
        }
      }
    )
  }
}
