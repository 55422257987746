export default {
  methods: {
    encodeToUrl: function (text) {
      return text.replace(' / ', 'slash').replace(' ', '-').toLowerCase()
    },

    decodeFromUrl: function (encoded) {
      return encoded.replace('slash', ' / ').replace('-', ' ')
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    }
  }
}

// export default { encodeToUrl, decodeFromUrl }
