<template>
  <div v-infinite-scroll="getVideos" infinite-scroll-immediate-check="false" infinite-scroll-disabled="reloadBusy">

    <v-container class="" style="max-width: 1100px;">

      <v-dialog v-model="vDialog" width="400px">
        <CommunityRedirectCard v-if="userDetails"
          :contentType="contentType"
        >
        </CommunityRedirectCard>
        <LoginCard v-else>
        </LoginCard>
      </v-dialog>

      <v-card flat outlined rounded color="transparent" class="justify-center">
        <v-card-title class="pb-1 pl-0 pt-2">
          <!-- <v-icon class="mr-2" x-large>
            mdi-web
          </v-icon> -->
          <h2>all communities</h2>

        </v-card-title>
        <v-card-actions class="py-0 pl-0 mt-2">
          <v-btn-toggle mandatory background-color="transparent" borderless tile v-model="tabModel">
            <v-btn @click="tabClicked('/clips')" color="transparent">
              Clips
            </v-btn>
            <v-btn @click="tabClicked('/videos')" color="transparent">
              Videos
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>

      <v-toolbar flat outlined rounded dense class="mt-4">

        <!-- ### DESKTOP ###-->
        <v-chip-group
          class="mr-4"
          v-if="!$vuetify.breakpoint.xs"
          mandatory
          active-class="secondary"
          v-model="sortBy"
          v-on:change="selectorChange()">
          <v-chip
            v-for="(item, index) in sortByItems"
            :key="index"
            :value="item.value"
            color="transparent"
          >
            <v-icon left small>
              {{ item.icon }}
            </v-icon>
            {{ item.label }}
          </v-chip>
        </v-chip-group>

        <!-- ### MOBILE ### -->
        <!-- ### sortBy ### -->
        <v-menu v-else bottom :offset-y="true" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs"  class="mr-3">
              <v-icon left small>
                {{ sortByItems[sortBy].icon }}
              </v-icon>
              {{ sortByItems[sortBy].label }}
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>

          <v-list class="cursor">
            <v-list-item-group v-model="sortBy" mandatory v-on:change="selectorChange()">
              <v-list-item v-for="(sort, index) in sortByItems" :key="index" :value="sort.value">
                <v-icon left small>
                  {{ sort.icon }}
                </v-icon>
                <v-list-item-title style="font-size: 14px;">{{ sort.label }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <!-- END MOBILE -->

        <!-- ### TIME SELECT DROPDOWN ###-->
        <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-1">
          <template v-slot:activator="{ on, attrs }">
            <v-chip :class="timeDisplay" v-on="on" v-bind="attrs">
              {{ time.label }}
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>

          <v-list class="cursor">
            <v-list-item-group v-model="time" mandatory v-on:change="selectorChange()">
              <v-list-item v-for="(item, index) in timeItems" :key="index" :value="item">
                <v-list-item-title style="font-size: 14px;">{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- ####### typeId ####### -->
        <!-- <v-menu bottom :offset-y="true" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs"  class="mr-3">
              <v-icon left small>
                {{ type.icon }}
              </v-icon>
              {{ type.name }}
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>

          <v-list class="cursor">
            <v-list-item-group v-model="typeIndex" mandatory v-on:change="selectorChange()">
              <v-list-item v-for="(item, index) in typeItems" :key="index" :value="index">
                <v-icon left small>
                  {{ item.icon }}
                </v-icon>
                <v-list-item-title style="font-size: 14px;">{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu> -->

        <v-spacer></v-spacer>

        <v-btn
          fab
          light
          small
          color="white"
          @click="setVDialog(true)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

      </v-toolbar>

      <div style="display: flex;">
      <v-col class="ma-0 pa-0" v-if="contentType === 'videos'">
        <v-select
          class="mt-4 mb-1"
          outlined
          hide-details
          dense
          placeholder="Filter by Duration..."
          :items="durationItems"
          v-model="duration"
          @change="categoryChange"
          :menu-props="{ 'offset-y': true, 'transition': 'slide-y-transition' }"
          width="170px"
          style="width: 170px; font-size: 12px !important; font-weight: bold;"
        >
        </v-select>
      </v-col>

      <v-switch
        class="ml-3 mb-1 mt-4"
        hide-details
        v-model="playerSwitch"
        label="Disable Player"
        v-if="!this.$vuetify.breakpoint.mdAndDown"
      >
      </v-switch>
      </div>

      <v-row v-if="!errorState && posts.length !== 0" wrap align-content-start class="mt-0">
        <!-- my-2 ?? -->
        <v-col v-for="(post, index) in posts" :key=index class="px-2 three-cols-b two-cols-b one-col-b">
          <VideoCard
            v-on:content-deleted="contentDeleted(index, post.postId)"
            v-on:clip-clicked="clipClicked(index)"
            v-on:primary-chip-selected="primaryChipSelected(post.ui_route_path)"
            v-on:secondary-chip-selected="primaryChipSelected(post.ui_route_path)"
            v-on:content-vote="contentVote(index, ...arguments)"
            v-bind="post"
            :primaryChipColor="post.chip_color"
            :chipLabel="post.community_name"
            :secondaryChipColor="post.cat_color"
            :secondaryChipLabel="post.category"
            :dbUpvote="service.upvote"
            :dbDownvote="service.downvote"
            :contentRoute="contentType"
            :duration="post.duration"
            :submitterId="post.userId"
            :submitterName1="post.submitterName1"
            :viewCount="post.view_count"
            :user_name="post.user_name"
            :playerSwitch="playerSwitch"
            :context="post.context"
            :isMain="true"
          >
          </VideoCard>
        </v-col>
      </v-row>
      <span v-else>
        <MainViewError v-if="errorState" :message="'something went wrong getting ' + contentType + ' ... please try again later'"></MainViewError>
        <MainViewError v-else message='be the first to submit with the + button above!'></MainViewError>
      </span>

    </v-container>
  </div>
</template>

<script>
// break the above out to another component?
import CommunityRedirectCard from '../components/CommunityRedirectCard'
import LoginCard from '../components/LoginCard'
import VideoCard from '../components/VideoCard'
import MainViewError from '../components/MainViewError'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VueRouter from 'vue-router'
import urlUtilsMixin from '../utils/urlUtils'

export default {
  name: 'VideoView',

  components: { VideoCard, MainViewError, CommunityRedirectCard, LoginCard },

  mixins: [urlUtilsMixin],

  data () {
    return {
      posts: [],
      sortByItems: {
        hot: {
          label: 'Hot',
          value: 'hot',
          icon: 'mdi-fire'
        },
        new: {
          label: 'New',
          value: 'new',
          icon: 'mdi-decagram-outline'
        },
        top: {
          label: 'Top',
          value: 'top',
          icon: 'mdi-transfer-up'
        }
      },
      timeItems: {
        day: {
          label: 'Today',
          query: 'day'
        },
        week: {
          label: 'Week',
          query: 'week'
        },
        month: {
          label: 'Month',
          query: 'month'
        },
        year: {
          label: 'Year',
          query: 'year'
        },
        all: {
          label: 'All Time',
          query: 'all'
        }
      },
      // typeItems: [
      //   {
      //     name: 'All',
      //     id: null,
      //     icon: 'mdi-web'
      //   },
      //   {
      //     name: 'Games',
      //     id: 1,
      //     icon: 'mdi-google-controller'
      //   },
      //   {
      //     name: 'Streamers',
      //     id: 2,
      //     icon: 'mdi-video-outline'
      //   }
      // ],
      type: {
        id: null
      },
      durationItems: [
        {
          text: 'All Durations',
          value: null
        },
        {
          text: 'Less than 10 mins',
          value: 10
        },
        {
          text: '10 - 30 mins',
          value: 30
        },
        {
          text: '30 mins - 1 hr',
          value: 60
        },
        {
          text: 'More than 1 hr',
          value: 61
        }
      ],
      duration: undefined,
      offset: 0,

      // getVideos error
      errorState: 0,
      category: 'All',
      sortBy: this.sortByInit,
      timeDataInit: this.timeInit,
      // typeIndex: 0,
      reloadBusy: true,
      playerSwitch: false,
      tabModel: 0
    }
  },

  methods: {

    ...mapActions(['triggerErrorNotification']),

    ...mapMutations(['setErrorNotification', 'setSuccessNotification', 'setVDialog', 'setPlayerInfo']),

    getVideos: async function () {
      // reactive way to get this once and it will update?
      try {
        var timeParam = null

        if (this.sortBy === 'top') {
          timeParam = this.time.query
        }
        const res = await this.service.getContent(this.sortBy, null, this.offset, timeParam, null, this.duration, this.type.id, null, undefined, null, null)
        this.posts = this.posts.concat(res.data)
        this.offset += res.data.length
        if (this.reloadBusy) {
          this.reloadBusy = false
        }
        // console.log('posts: ' + JSON.stringify(this.posts))
      } catch (err) {
        this.errorState = 1
      }
    },

    // if route is directly pushed, will get route navigation failures in console
    tabClicked: function (route) {
      this.$router.push(route).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // do nothing
        } else {
          this.setErrorNotification({ trigger: true })
        }
      })
    },

    // push the route on change, watcher on route causes the reload. On duplicated nav failure manually reload
    selectorChange: function () {
      var routeConfig = {
        name: 'Videos',
        params: {
          content: this.contentType,
          sortByUrl: this.sortBy
        },
        query: this.timeQuery
      }

      this.$router.push(routeConfig).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          this.offset = 0
          this.posts = []
          this.reloadBusy = true
          this.getVideos()
        } else {
          this.setErrorNotification({ trigger: true })
        }
      })
    },

    // for duration
    categoryChange: function () {
      this.offset = 0
      this.posts = []
      this.reloadBusy = true
      this.getVideos() // await?
    },

    clipClicked: async function (index) {
      this.setPlayerInfo({
        community: {},
        posts: this.posts,
        postIndex: index,
        modEnabled: false,
        offset: this.offset,
        creatorFilter: this.creatorFilter
      })

      var routeConfig = '/player/' + this.contentType + '/' + this.sortBy
      if (this.sortBy === 'top') {
        routeConfig = routeConfig + '?t=' + this.time.query
      }
      this.$router.push(routeConfig)
    },

    primaryChipSelected: function (route) {
      // this.$router.push({ name: 'GameVideos', params: { game: route, content: this.contentType } })
      var routeConfig = '/' + route
      this.$router.push(routeConfig)
    },

    contentDeleted: async function (deleteIndex, postId) {
      try {
        await this.service.deleteContent(postId)
        this.posts.splice(deleteIndex, 1)
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    contentVote: function (index, upvotes, upvoted, downvoted) {
      if (upvoted) {
        this.posts[index].init_upvoted = 1
      } else if (downvoted) {
        this.posts[index].init_upvoted = -1
      } else {
        this.posts[index].init_upvoted = null
      }
      this.posts[index].upvotes = upvotes.toString()
    }
  },

  props: {
    sortByInit: {
      type: String,
      default: 'hot'
    },
    timeInit: {
      type: String,
      default: 'week'
    },
    errorInit: Boolean,
    service: Object,
    contentType: {
      type: String,
      default: 'clips'
    }
  },

  computed: {
    ...mapGetters(['userDetails']),

    vDialog: {
      get () {
        return this.$store.state.twitterAuth.vDialog
      },

      set (value) {
        this.$store.commit('setVDialog', value)
      }
    },

    errorNotification: {
      get () {
        return this.$store.state.twitterAuth.errorNotification
      },

      set (value) {
        this.setErrorNotification({ trigger: value })
      }
    },

    successNotification: {
      get () {
        return this.$store.state.twitterAuth.successNotification
      },

      set (value) {
        this.setSuccessNotification({ trigger: value })
      }
    },

    time: {
      get () {
        return this.timeItems[this.timeDataInit]
      },
      set (newObj) {
        this.timeDataInit = newObj.query
        return newObj
      }
    },

    // type: {
    //   get () {
    //     return this.typeItems[this.typeIndex]
    //   },
    //   set (newObj) {
    //     this.typeIndex = newObj
    //   }
    // },

    timeDisplay: function () {
      return this.sortBy === 'top' ? 'ml-n1 mr-3 ml-sm-n4 mr-sm-6' : 'd-none'
    },

    timeQuery: function () {
      if (this.sortBy === 'top') {
        return { t: this.time.query }
      } else {
        return null
      }
    }
  },

  // beforeMount was here before, why?
  created: async function () {
    if (this.errorInit) {
      this.setErrorNotification({ trigger: this.errorInit })
    }

    if (this.contentType === 'videos') {
      this.tabModel = 1
    }
    this.reloadBusy = true
    this.getVideos()
  },

  watch: {
    $route (to, from) {
      if (to.params.sortByUrl) {
        this.sortBy = to.params.sortByUrl
      }

      if (to.params.content === 'clips') {
        this.duration = undefined
      }

      this.offset = 0
      this.posts = []
      this.reloadBusy = true
      this.getVideos()
    }
  }
}
</script>

<style scoped>

  @media only screen and (min-width: 991px) {
    .three-cols-b {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 990px) {
    .two-cols-b {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }
  @media only screen and (max-width: 600px) {
    .one-col-b {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @media only screen and (min-width: 1600px) and (max-width: 2000px) {
    .five-cols {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }

    .selector {
      width: 40%;
    }

  }

  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .four-cols {
      width: 25%;
      max-width: 25%;
      flex-basis: 25%;
    }
  }

  @media only screen and (min-width: 990px) and (max-width: 1301px) {
    .three-cols {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 989px) {
    .two-cols {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }

  @media only screen and (max-width: 599px) {
    .one-col {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
</style>
