<template>
  <v-img contain height="18px" width="18px" src="@/assets/Twitch_icon.png"></v-img>
</template>

<script>
export default {
  name: 'TwitchLogoIcon'
//   props: {
//     imgsrc: String
//   }
}
</script>
