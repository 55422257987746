<template>
  <!-- color="#f9f9f9" -->
  <!-- <v-card color="transparent" class="" outlined tile :href="this.url" target="_blank"> -->
  <v-card color="transparent" class="" outlined tile :href="this.href" target="_blank" @click="cardClicked">
    <v-img class="thumbnail"
      :src="thumbnailUrl" :width="isEmote ? '112' : ''" :height="isEmote ? '112' : ''">
    </v-img>
    <p :class="viewCount ? 'mb-0 justify-end pr-1 pb-1 duration' : 'mb-0 justify-end pr-1 pb-1 duration-short'" style="display: flex">
      <span class="mr-0 duration-color" style="padding: 1px 2px 1px 2px">
        &nbsp;{{ duration }}&nbsp;
      </span>
    </p>
    <p v-if="watched && !isMain" class="mb-0 justify-end pa-1 watched" style="display: flex">
      <span class="mr-0 duration-color" style="padding: 2px 2px 2px 2px">
        &nbsp;Watched on stream&nbsp;
        <v-icon small style="bottom: 1px">mdi-eye</v-icon>&nbsp;
      </span>
    </p>

    <div class="px-1">
    <v-card-title v-line-clamp="2" id="title" class="py-0 mt-3 pl-0 text-subtitle-1 font-weight-bold custom-title" style="word-break: keep-all !important;">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="py-0 mt-4 pl-0" style="margin-bottom: 10px;">
      <a :href="this.creatorInfo.link" target="_blank" @click.stop @mousedown.stop @touchstart.stop :style="'color: white; background-color: ' + creatorInfo.color" class="creator-link user-text creator-background">
        <v-icon dense :style="creatorInfo.iconStyle">
          {{ creatorInfo.icon }}
        </v-icon>
        {{ creatorName }}
      </a>
    </v-card-subtitle>
    <v-card-subtitle v-if="viewCount" class="pa-0 mx-0 mt-0 text--secondary" style="margin-bottom: -3px">
      {{ views }} views
    </v-card-subtitle>

    <v-card-subtitle class="pa-0 text--secondary" style="margin-bottom: -3px;" @click.stop @mousedown.stop @touchstart.stop>
      submitted by:
      <!-- <router-link :to="'/user/' + this.user_name + '/' + this.contentRoute" class="user-text submit-by"> -->
      <router-link :to="'/user/' + this.user_name + '/clips'" class="user-text submit-by">
        {{ submitterName1 }}
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-show="badge_icon" :small="badge_icon === 'mdi-sword mdi-flip-h'" :dense="badge_icon === 'mdi-discord'" :color="badge_color" :style="badge_style" v-on="on" v-bind="attrs">
              {{ badge_icon }}
            </v-icon>
          </template>
          <span style="font-size: 14px;">
            {{ badge_text }}
          </span>
        </v-tooltip>
      </router-link>
    </v-card-subtitle>

    <v-card-subtitle class="pt-0 pl-0 pb-0 text--secondary" @click.stop @mousedown.stop @touchstart.stop>
      {{ submitDate }}
    </v-card-subtitle>

    <v-card-text class="pl-0 pt-2 pb-0" style="display: flex;">
      <v-chip :color="primaryChipColor" @click.prevent="$emit('primary-chip-selected')" @click.stop @mousedown.stop @touchstart.stop>
        {{ chipLabel }}
      </v-chip>
      <v-chip v-if="secondaryChipLabel" class="ml-1" :color="secondaryChipColor" @click.prevent="$emit('secondary-chip-selected')" @click.stop @mousedown.stop @touchstart.stop>
        {{ secondaryChipLabel }}
      </v-chip>

      <v-spacer></v-spacer>

      <!-- <v-tooltip top v-if="context" :open-on-hover="false" :open-on-click="false" :open-on-focus="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon outlined v-on="on" v-bind="attrs" @click.stop>
            <v-icon small>
              mdi-message-text-outline
            </v-icon>
          </v-btn>
        </template>
        <div style="font-size: 12px;">
          {{ context }}
        </div>
      </v-tooltip> -->

    </v-card-text>
    <v-card-actions @click.stop @mousedown.stop @touchstart.stop class="pl-0">
      <v-btn icon :color="upvoteColor" :ripple="{ class: 'green--text' }" @click.prevent="upvote">
        <v-icon v-if="!isMod" large>mdi-chevron-up</v-icon>
        <v-icon v-else large>mdi-chevron-double-up</v-icon>
      </v-btn>
      <v-card-subtitle class='pa-0'>{{ localVotes }}</v-card-subtitle>
      <v-btn icon :color="downvoteColor" :ripple="{ class: 'red--text' }" @click.prevent="downvote">
        <v-icon v-if="!isMod" large>mdi-chevron-down</v-icon>
        <v-icon v-else large>mdi-chevron-double-down</v-icon>
      </v-btn>
      <!-- favorites tech debt -->
      <v-btn v-if="contentRoute !== 'videos'" icon @click.prevent="favoriteClicked">
        <v-icon>{{ favorited ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <span v-if="isMod && !secondaryChipLabel">
        <v-tooltip top v-if="!watched">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="green" :ripple="{ class: 'green--text' }" class="mr-3" @click.prevent="markWatched(true)" v-on="on" v-bind="attrs">
              <v-icon large>mdi-check</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 14px;">
            Watched
          </span>
        </v-tooltip>
        <v-tooltip top v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" @click.prevent="markWatched(false)" v-on="on" v-bind="attrs">
              <v-icon large>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 14px;">
            Undo Watched
          </span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :ripple="{ class: 'green--text' }" class="mr-3" @click.prevent="markHidden" v-on="on" v-bind="attrs">
              <v-icon large>mdi-eye-off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 14px;">
            Hide
          </span>
        </v-tooltip>
      </span>
      <v-tooltip top v-if="isMod || (userDetails && (user_name === userDetails.username))">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="red" :ripple="{ class: 'red--text' }" @click.prevent="deleteContent" v-on="on" v-bind="attrs">
            <v-icon large>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span style="font-size: 14px;">
            Delete
        </span>
      </v-tooltip>
    </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getCreatorInfo, getFormattedViews, getFormattedSubmitDate } from '../utils/formatter'
export default {
  name: 'VideoCard',
  // <v-icon large @click.stop="upvote" @mousedown.stop @touchstart.stop :color="this.upvoteColor" :ripple="{ center: false }">mdi-chevron-up</v-icon>

  data () {
    return {
      // TODO: this.upvotes is a string?? guess cause of coalesce, postgres CAST?
      localVotes: parseInt(this.upvotes),
      upvoted: false,
      downvoted: false,
      modUpvoted: false,
      modDownvoted: false,
      favorited: this.init_favorited
    }
  },

  methods: {

    ...mapActions(['triggerErrorNotification']),

    ...mapMutations(['setVDialog', 'setErrorNotification', 'setBanNotification']),

    // TODO: use subpage.type rather than content route to determine how the player should be
    cardClicked: function () {
      if ((this.contentRoute === 'clips' || this.contentRoute === 'videos' || this.contentRoute === 'santa' || this.contentRoute === 'favorites') && !this.playerSwitch && !this.$vuetify.breakpoint.mdAndDown) {
        this.$emit('clip-clicked')
      }
    },

    upvote: async function () {
      try {
        if (this.userDetails) {
          if (this.upvoted) {
            if (this.modUpvoted) {
              this.localVotes -= 5
              this.modUpvoted = false
            } else {
              this.localVotes--
            }
            await this.dbUpvote(this.postId)
          } else if (this.downvoted) {
            if (this.modDownvoted) {
              this.localVotes += 2
              this.modDownvoted = false
            } else {
              this.localVotes += 1
            }

            if (this.isMod) {
              this.localVotes += 5
              this.modUpvoted = true
              await this.dbSuperUpvote(this.postId, this.gameId)
            } else {
              this.localVotes += 1
              await this.dbUpvote(this.postId)
            }
            this.downvoted = false
          } else {
            if (this.isMod) {
              this.localVotes += 5
              this.modUpvoted = true
              await this.dbSuperUpvote(this.postId, this.gameId)
            } else {
              this.localVotes++
              await this.dbUpvote(this.postId)
            }
          }
          this.upvoted = !this.upvoted
          this.$emit('content-vote', this.localVotes, this.upvoted, this.downvoted, this.modUpvoted, this.modDownvoted)
        } else {
          this.setVDialog(true)
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setBanNotification(true)
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    downvote: async function () {
      try {
        if (this.userDetails) {
          if (this.downvoted) {
            if (this.modDownvoted) {
              this.localVotes += 2
              this.modDownvoted = false
            } else {
              this.localVotes += 1
            }
            await this.dbDownvote(this.postId)
          } else if (this.upvoted) {
            if (this.modUpvoted) {
              this.localVotes -= 5
              this.modUpvoted = false
            } else {
              this.localVotes -= 1
            }

            if (this.isMod) {
              this.localVotes -= 2
              this.modDownvoted = true
              await this.dbSuperDownvote(this.postId, this.gameId)
            } else {
              this.localVotes -= 1
              await this.dbDownvote(this.postId)
            }
            this.upvoted = false
          } else {
            if (this.isMod) {
              this.localVotes -= 2
              this.modDownvoted = true
              await this.dbSuperDownvote(this.postId, this.gameId)
            } else {
              this.localVotes--
              await this.dbDownvote(this.postId)
            }
          }
          this.downvoted = !this.downvoted

          this.$emit('content-vote', this.localVotes, this.upvoted, this.downvoted, this.modUpvoted, this.modDownvoted)
        } else {
          this.setVDialog(true)
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setBanNotification(true)
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    // downvote: async function () {
    //   try {
    //     if (this.userDetails) {
    //       await this.dbDownvote(this.postId)
    //       if (this.downvoted) {
    //         this.localVotes++
    //       } else if (this.upvoted) {
    //         if (this.modUpvoted) {
    //           this.localVotes -= 6
    //           this.modUpvoted = false
    //         } else {
    //           this.localVotes -= 2
    //         }
    //         this.upvoted = false
    //       } else {
    //         this.localVotes--
    //       }
    //       this.downvoted = !this.downvoted
    //       this.$emit('content-vote', this.localVotes, this.upvoted, this.downvoted, this.modUpvoted, this.modDownvoted)
    //     } else {
    //       this.setVDialog(true)
    //     }
    //   } catch (error) {
    //     if (error.response && error.response.status === 403) {
    //       this.setBanNotification(true)
    //     } else {
    //       this.setErrorNotification({ trigger: true })
    //     }
    //   }
    // },

    deleteContent: function () {
      this.$emit('content-deleted')
    },

    markWatched: function (value) {
      this.$emit('mark-watched', value)
    },

    markHidden: function () {
      this.$emit('mark-hidden')
    },

    favoriteClicked: function () {
      if (this.userDetails) {
        this.favorited = !this.favorited
        this.$emit('favorite-clicked')
      } else {
        this.setVDialog(true)
      }
    }
  },

  computed: {
    upvoteColor: function () {
      if (this.upvoted) {
        return 'green'
      } else {
        return 'gray'
      }
    },

    downvoteColor: function () {
      if (this.downvoted) {
        return 'red'
      } else {
        return 'gray'
      }
    },

    href: function () {
      if ((this.contentRoute === 'clips' || this.contentRoute === 'santa' || this.contentRoute === 'videos' || this.contentRoute === 'favorites') && !this.playerSwitch && !this.$vuetify.breakpoint.mdAndDown) {
        return undefined
      } else {
        return this.url
      }
    },

    ...mapGetters(['userDetails']),

    creatorInfo: function () {
      return getCreatorInfo(this.content_type, this.channel_id)
    },

    views: function () {
      return getFormattedViews(this.viewCount)
    },

    submitDate: function () {
      return getFormattedSubmitDate(this.submitTimestamp)
    }
  },

  created: function () {
    if (this.init_upvoted == null) {
      this.upvoted = false
      this.downvoted = false
    } else if (this.init_upvoted > 0) {
      this.upvoted = true
      this.downvoted = false
      if (this.init_upvoted > 1) {
        this.modUpvoted = true
      }
    } else if (this.init_upvoted < 0) {
      this.upvoted = false
      this.downvoted = true
      if (this.init_upvoted < -1) {
        this.modDownvoted = true
      }
    }
  },

  watch: {
    postId: function () {
      this.localVotes = parseInt(this.upvotes)
      if (this.init_upvoted == null) {
        this.upvoted = false
        this.downvoted = false
      } else if (this.init_upvoted > 0) {
        this.upvoted = true
        this.downvoted = false
      } else if (this.init_upvoted < 0) {
        this.upvoted = false
        this.downvoted = true
      }
    }
  },

  props: {
    // post: Object
    postId: Number,
    gameId: Number,
    thumbnailUrl: String,
    title: String,
    duration: String,
    creatorName: String,
    submitterName1: String,
    user_name: String,
    url: String,
    init_upvoted: Number,
    upvotes: String,
    dbUpvote: Function,
    dbSuperUpvote: Function,
    dbDownvote: Function,
    dbSuperDownvote: Function,
    category: String,
    primaryChipColor: String,
    secondaryChipLabel: String,
    secondaryChipColor: String,
    channel_id: String,
    chipLabel: String,
    submitterId: Number,
    badge_icon: String,
    badge_color: String,
    badge_style: String,
    badge_text: String,
    viewCount: String,
    content_type: Number,
    submitTimestamp: String,
    playerSwitch: {
      type: Boolean,
      default: false
    },
    isEmote: {
      type: Boolean,
      default: false
    },
    isMod: {
      type: Boolean,
      default: false
    },
    contentRoute: {
      type: String,
      default: 'videos'
    },
    watched: {
      type: Boolean,
      default: false
    },
    isMain: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: null
    },
    init_favorited: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

  .creator-background {
    border-radius: 50px;
    padding: 5px 8px 5px 8px;
  }

  .user-text:hover {
    color: white;
    font-weight: bold;
  }

  .thumbnail {
    position: relative;
  }

  .duration {
    position: absolute;
    bottom: 259px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
  }

  .duration-short {
    position: absolute;
    bottom: 240px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
  }

  .watched {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: bold;
  }

  .duration-color {
   color: white;
   background: rgb(0, 0, 0); /* fallback color */
   background: rgba(0, 0, 0, 0.7);
   border-radius: 3px;
  }

  .creator-link {
    text-decoration: none;
    color: inherit;
  }

  .submit-by {
    text-decoration: none;
    color: white;
  }

  .focus-none {
    outline: none !important;
    box-shadow: none !important;
  }

  .custom-title {
    line-height: 1.4em !important;
    height: 2.8em;
  }

  .card {
    color: #f9f9f9
  }
</style>
