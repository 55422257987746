import VideoService from '../services/VideoService.js'
import ClipService from '../services/ClipService.js'
import ImgurService from '../services/ImgurService'

function getContentService (content) {
  if (content === 1) {
    return ClipService
  } else if (content === 2) {
    return VideoService
  } else if (content === 3) {
    return ImgurService
  }

  // default
  return ClipService
}

export default getContentService
