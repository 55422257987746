import axios from 'axios'

// export default () => {
//   return axios.create({
//     baseURL: 'http://localhost:3003'
//     // headers: { 'Access-Control-Allow-Origin': '*' }
//   })
// }

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
    // headers: { 'Access-Control-Allow-Origin': '*' }
  })
}
