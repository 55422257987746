<template>
  <v-app>
    <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no">
    <v-snackbar
      top
      v-model="errorNotification"
      :timeout="10000">
      <v-icon class="pr-2" color="red" large>mdi-alert-circle</v-icon>
      <span>{{ errorMessage }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="setErrorNotification({ trigger: false })"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      top
      v-model="successNotification"
      :timeout="6000">
      <v-icon class="pr-2" color="green" large>mdi-check-circle</v-icon>
      <span>{{ successMessage }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="setSuccessNotification({ trigger: false })"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      top
      v-model="emoteNotification"
      :timeout="10000">
      <v-row>
        <v-col :cols="1">
          <v-icon class="pr-2" color="red" large>mdi-alert-circle</v-icon>
        </v-col>
        <v-col>
          <span>Please check the submission rules, and make sure the width and height of your image(s) are the same</span>
        </v-col>
      </v-row>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="setEmoteNotification(false)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      top
      v-model="banNotification"
      :timeout="10000"
      >
      <v-icon class="pr-2" color="red" large>mdi-alert-circle</v-icon>
      <span>Your account has been banned... probably for mass downvoting or abusing the site in some other way. Reach out to @slink_dev on twitter with any questions.</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="setBanNotification(false)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <AppBar/>
    <v-dialog v-model="deleteRequested" width="400px">
      <DeleteCard>
      </DeleteCard>
    </v-dialog>
    <v-main :style="backgroundColor">
        <!-- :key="userDetails ? userDetails.username : 0" -->
        <router-view :key="loginChange"></router-view>
    </v-main>
  </v-app>
</template>

<script>
// ++++++++++++++++++++++++++++++++++++++++++++++++++++ BACKGROUND COLOR
// import VideoView from './views/VideoView'
import AppBar from './components/AppBar'
import DeleteCard from './components/DeleteCard'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    AppBar,
    DeleteCard
  },

  data: () => ({
  }),

  methods: {
    ...mapMutations(['setErrorNotification', 'setEmoteNotification', 'setSuccessNotification', 'setBanNotification'])
  },

  computed: {
    ...mapGetters(['loginChange', 'errorMessage', 'successMessage']),

    ...mapState({
      deleteRequested: state => state.twitterAuth.deleteRequested
    }),

    backgroundColor: function () {
      // #0e091b
      // #181818
      return this.$vuetify.theme.dark ? 'background-color: #0e0e10;' : 'background-color: #f9f9f9;'
    },

    errorNotification: {
      get () {
        return this.$store.state.twitterAuth.errorNotification
      },

      set (value) {
        this.setErrorNotification({ trigger: value })
      }
    },

    successNotification: {
      get () {
        return this.$store.state.twitterAuth.successNotification
      },

      set (value) {
        this.setSuccessNotification({ trigger: value })
      }
    },

    banNotification: {
      get () {
        return this.$store.state.twitterAuth.banNotification
      },

      set (value) {
        this.setBanNotification(value)
      }
    },

    emoteNotification: {
      get () {
        return this.$store.state.twitterAuth.emoteNotification
      },

      set (value) {
        this.setEmoteNotification(value)
      }
    }
  }
}
</script>

<style>
  /* .side {
    /* background-color: #f9f9f9; */
    /* background-color: #f9f9f9
  } */

  .main {
    height: 100%;
    width: 80%;
    background-color: white;
    margin: 0 auto;
  }

  .cursor {
    cursor: pointer
  }

  ::-webkit-scrollbar {
    width: 16px;
    padding: 4px;
  }

  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 10px #0e0e10; */
    background-color: #0e0e10;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid #0e0e10;
    border-radius: 9999px;
    background-color: #aaa;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #7a7a7a;
  }

</style>
