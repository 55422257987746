import Api from './Api'

export default {
  getRanking (gameId, time) {
    return Api().get('rank',
      {
        withCredentials: true,
        params: {
          id: gameId,
          time: time
        }
      }
    )
  }
}
