import { MINUTE, HOUR, DAY, MONTH } from '../utils/constants'

export function getCreatorInfo (contentType, channelId) {
  if (contentType === 1) {
    return {
      link: 'https://twitch.tv/' + channelId,
      color: '#673AB7',
      icon: 'mdi-twitch',
      iconStyle: '',
      listIcon: '$vuetify.icons.customtwitch',
      listIconStyle: ''
    }
  } else if (contentType === 3 || contentType === 4) {
    return {
      link: channelId ? 'https://youtube.com/channel/' + channelId : null,
      color: '#FF0000',
      icon: 'mdi-youtube',
      iconStyle: 'padding-bottom: 1.5px',
      listIcon: 'mdi-youtube',
      listIconStyle: 'padding-bottom: 1.5px; background-image: linear-gradient(to bottom, black, white 5%, white 90%, black 90%); background-size: 6px 6px; background-position: center; background-repeat: no-repeat;'
    }
  } else {
    return {}
  }
}

export function getFormattedViews (viewCount) {
  return (viewCount + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getFormattedSubmitDate (submitDate) {
  const suffix = ' ago'
  const date1 = new Date(submitDate)
  var date2 = Date.now()
  var diffMs = date2 - date1.valueOf()
  var diffMonths = diffMs / MONTH
  if (diffMonths >= 1) {
    diffMonths = Math.round(diffMonths)
    if (diffMonths === 1) {
      return `${diffMonths} month` + suffix
    }
    return `${diffMonths} months` + suffix
  }
  var diffDays = diffMs / DAY
  if (diffDays >= 1) {
    diffDays = Math.round(diffDays)
    if (diffDays === 1) {
      return 'Yesterday'
    }
    return `${diffDays} days` + suffix
  }
  var diffHours = diffMs / HOUR
  if (diffHours >= 1) {
    diffHours = Math.round(diffHours)
    if (diffHours === 1) {
      return `${diffHours} hour` + suffix
    }
    return `${diffHours} hours` + suffix
  }
  var diffMins = Math.round(diffMs / MINUTE)
  if (diffMins === 1) {
    return `${diffMins} minute` + suffix
  }
  if (diffMins === 0) {
    return 'Just Now'
  }
  return `${diffMins} minutes` + suffix
}
