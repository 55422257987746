import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import ImageIcon from '../components/ImageIcon'
import DiscordIcon from '../components/DiscordIcon'
import TwitchLogoIcon from '../components/TwitchLogoIcon'
import TiktokLogoIcon from '../components/TiktokLogoIcon'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true
  },
  icons: {
    iconfont: 'mdi',
    values: {
      customgoogle: {
        component: ImageIcon
      },
      customtwitch: {
        component: TwitchLogoIcon
      },
      customdiscord: {
        component: DiscordIcon
      },
      customtiktok: {
        component: TiktokLogoIcon
      }
    }
  }
})
