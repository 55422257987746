<template>
  <v-card outlined>
    <v-card-title class="justify-center">Login / Sign Up</v-card-title>
    <v-card-text class="text-center">
      Login with Twitter or Twitch to submit and vote on videos and clips.
    </v-card-text>
    <!-- <v-btn rounded dark @click="twitterLoginClicked" class="ma-auto mb-5" style="display: flex;" width="217.48px" color="#1DA1F2">
      <v-icon left>
        mdi-twitter
      </v-icon>
      Login with Twitter
    </v-btn> -->
    <v-btn rounded dark @click="twitchLoginClicked" class="ma-auto mb-5" style="display: flex;" width="217.48px" color="#9147ff">
      <v-icon left>
        mdi-twitch
      </v-icon>
      Login with Twitch
    </v-btn>
    <v-btn rounded light @click="googleLoginClicked" class="ma-auto" style="display: flex;" width="217.48px" color="#FFFFFF">
      <v-icon left>
        $vuetify.icons.customgoogle
      </v-icon>
      Login with Google
    </v-btn>
    <v-card-actions class="pt-5">
      <v-spacer></v-spacer>
      <v-btn
        @click="setVDialog(false)"
        outlined
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import UserService from '../services/UserService'
import { mapMutations } from 'vuex'
export default {
  name: 'LoginCard',
  data () {
    return {
      twitterApiUrl: process.env.VUE_APP_API_BASE_URL + '/twitter',
      twitchApiUrl: process.env.VUE_APP_API_BASE_URL + '/twitch',
      googleApiUrl: process.env.VUE_APP_API_BASE_URL + '/google'
    }
  },

  methods: {

    ...mapMutations(['setVDialog']),

    twitterLoginClicked: async function () {
      try {
        // const res = await UserService.loginWithTwitter()
        // console.log('res: ' + res.data)
        // console.log('status: ' + res.status + ' status: ' + res.statusText)
        window.location.href = this.twitterApiUrl + '?p=' + this.$route.path.substring(1)
      } catch (err) {
        console.log('L')
      }
    },

    twitchLoginClicked: async function () {
      try {
        window.location.href = this.twitchApiUrl + '?p=' + this.$route.path.substring(1)
      } catch (err) {
        console.log('L')
      }
    },

    googleLoginClicked: async function () {
      try {
        window.location.href = this.googleApiUrl + '?p=' + this.$route.path.substring(1)
      } catch (err) {
        console.log('L')
      }
    }
  }

}
</script>

<style scoped>
</style>
