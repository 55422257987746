var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"color":"transparent","outlined":"","tile":"","href":this.url,"target":"_blank","width":_vm.width}},[_c('v-img',{staticClass:"thumbnail mx-auto",attrs:{"contain":"","width":_vm.imageDetails.size,"height":_vm.imageDetails.size,"src":_vm.imageDetails.src}}),_c('v-card-title',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(2),expression:"2"}],staticClass:"py-0 mt-3 pl-0 text-subtitle-1 font-weight-bold custom-title",staticStyle:{"word-break":"keep-all !important"},attrs:{"id":"title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-subtitle',{staticClass:"pt-0 pl-0 pb-0 mt-1",on:{"click":function($event){$event.stopPropagation();},"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();}}},[_vm._v(" submitted by: "),_c('router-link',{staticClass:"user-text submit-by",attrs:{"to":'/user/' + this.user_name + '/emotes'}},[_vm._v(" "+_vm._s(_vm.submitterName1)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.badge_icon),expression:"badge_icon"}],style:(_vm.badge_style),attrs:{"small":_vm.badge_icon === 'mdi-sword mdi-flip-h',"dense":_vm.badge_icon === 'mdi-discord',"color":_vm.badge_color}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.badge_icon)+" ")])]}}])},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.badge_text)+" ")])])],1)],1),_c('v-card-text',{staticClass:"pl-0 pt-2 pb-0"},[_c('v-chip',{attrs:{"color":_vm.primaryChipColor},on:{"click":[function($event){$event.preventDefault();return _vm.$emit('primary-chip-selected')},function($event){$event.stopPropagation();}],"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.chipLabel))])],1),_c('v-card-actions',{staticClass:"pl-0",on:{"click":function($event){$event.preventDefault();},"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":"","color":_vm.upvoteColor,"ripple":{ class: 'green--text' }},on:{"click":function($event){$event.preventDefault();return _vm.upvote.apply(null, arguments)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-up")])],1),_c('v-card-subtitle',{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.localVotes))]),_c('v-btn',{attrs:{"icon":"","color":_vm.downvoteColor,"ripple":{ class: 'red--text' }},on:{"click":function($event){$event.preventDefault();return _vm.downvote.apply(null, arguments)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-down")])],1),_c('v-spacer'),(_vm.isMod)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":"","color":"yellow","ripple":{ class: 'yellow--text' }},on:{"click":function($event){$event.preventDefault();return _vm.markWatched.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-trophy")])],1)]}}],null,false,1051086455)},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" Winner ")])])],1):_vm._e(),(_vm.isMod || (_vm.userDetails && (_vm.user_name === _vm.userDetails.username)))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","ripple":{ class: 'red--text' }},on:{"click":function($event){$event.preventDefault();return _vm.deleteContent.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-delete")])],1)]}}],null,false,1454869450)},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" Delete ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }