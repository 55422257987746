import Api from './Api'

export default {

  loginWithTwitter () {
    return Api().get('twitter', { withCredentials: true })
  },

  logout () {
    return Api().get('user/logout', { withCredentials: true })
  },

  getUserDetails () {
    return Api().get('user', { withCredentials: true })
  },

  getSpecificUser (name) {
    return Api().get('user/specific',
      {
        withCredentials: true,
        params: {
          name: name
        }
      }
    )
  },

  deleteAccount () {
    return Api().delete('user/delete',
      {
        withCredentials: true
      }
    )
  }
}
