var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('meta',{attrs:{"name":"viewport","content":"width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"}}),_c('v-snackbar',{attrs:{"top":"","timeout":10000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.setErrorNotification({ trigger: false })}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.errorNotification),callback:function ($$v) {_vm.errorNotification=$$v},expression:"errorNotification"}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"red","large":""}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(_vm._s(_vm.errorMessage))])],1),_c('v-snackbar',{attrs:{"top":"","timeout":6000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"green","text":""},on:{"click":function($event){return _vm.setSuccessNotification({ trigger: false })}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.successNotification),callback:function ($$v) {_vm.successNotification=$$v},expression:"successNotification"}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"green","large":""}},[_vm._v("mdi-check-circle")]),_c('span',[_vm._v(_vm._s(_vm.successMessage))])],1),_c('v-snackbar',{attrs:{"top":"","timeout":10000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.setEmoteNotification(false)}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.emoteNotification),callback:function ($$v) {_vm.emoteNotification=$$v},expression:"emoteNotification"}},[_c('v-row',[_c('v-col',{attrs:{"cols":1}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"red","large":""}},[_vm._v("mdi-alert-circle")])],1),_c('v-col',[_c('span',[_vm._v("Please check the submission rules, and make sure the width and height of your image(s) are the same")])])],1)],1),_c('v-snackbar',{attrs:{"top":"","timeout":10000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.setBanNotification(false)}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.banNotification),callback:function ($$v) {_vm.banNotification=$$v},expression:"banNotification"}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"red","large":""}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v("Your account has been banned... probably for mass downvoting or abusing the site in some other way. Reach out to @slink_dev on twitter with any questions.")])],1),_c('AppBar'),_c('v-dialog',{attrs:{"width":"400px"},model:{value:(_vm.deleteRequested),callback:function ($$v) {_vm.deleteRequested=$$v},expression:"deleteRequested"}},[_c('DeleteCard')],1),_c('v-main',{style:(_vm.backgroundColor)},[_c('router-view',{key:_vm.loginChange})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }