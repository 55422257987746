<template>
  <!-- color="#f9f9f9" -->
  <v-card color="transparent" class="mx-auto" outlined tile :href="this.url" target="_blank" :width="width">
    <v-img contain :width="imageDetails.size" :height="imageDetails.size" class="thumbnail mx-auto"
      :src="imageDetails.src" >
    </v-img>
    <v-card-title v-line-clamp="2" id="title" class="py-0 mt-3 pl-0 text-subtitle-1 font-weight-bold custom-title" style="word-break: keep-all !important;">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="pt-0 pl-0 pb-0 mt-1" style="" @click.stop @mousedown.stop @touchstart.stop>
      submitted by:
      <router-link :to="'/user/' + this.user_name + '/emotes'" class="user-text submit-by">
      {{ submitterName1 }}
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-show="badge_icon" :small="badge_icon === 'mdi-sword mdi-flip-h'" :dense="badge_icon === 'mdi-discord'" :color="badge_color" :style="badge_style" v-on="on" v-bind="attrs">
              {{ badge_icon }}
            </v-icon>
          </template>
          <span style="font-size: 14px;">
            {{ badge_text }}
          </span>
        </v-tooltip>
      </router-link>
    </v-card-subtitle>
    <v-card-text class="pl-0 pt-2 pb-0">
      <v-chip :color="primaryChipColor" @click.prevent="$emit('primary-chip-selected')" @click.stop @mousedown.stop @touchstart.stop>{{ chipLabel }}</v-chip>
    </v-card-text>
    <v-card-actions @click.prevent @mousedown.stop @touchstart.stop class="pl-0">
      <v-btn icon :color="upvoteColor" :ripple="{ class: 'green--text' }" @click.prevent="upvote">
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
      <v-card-subtitle class='pa-0'>{{ localVotes }}</v-card-subtitle>
      <v-btn icon :color="downvoteColor" :ripple="{ class: 'red--text' }" @click.prevent="downvote">
        <v-icon large>mdi-chevron-down</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <span v-if="isMod">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="yellow" :ripple="{ class: 'yellow--text' }" class="mr-3" @click.prevent="markWatched" v-on="on" v-bind="attrs">
              <v-icon large>mdi-trophy</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 14px;">
            Winner
          </span>
        </v-tooltip>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :ripple="{ class: 'green--text' }" class="mr-3" @click.prevent="markHidden" v-on="on" v-bind="attrs">
              <v-icon large>mdi-eye-off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 14px;">
            Hide
          </span>
        </v-tooltip> -->
      </span>
      <v-tooltip top v-if="isMod || (userDetails && (user_name === userDetails.username))">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="red" :ripple="{ class: 'red--text' }" @click.prevent="deleteContent" v-on="on" v-bind="attrs">
            <v-icon large>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span style="font-size: 14px;">
            Delete
        </span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ImgurCard',
  // <v-icon large @click.stop="upvote" @mousedown.stop @touchstart.stop :color="this.upvoteColor" :ripple="{ center: false }">mdi-chevron-up</v-icon>

  data () {
    return {
      // TODO: this.upvotes is a string?? guess cause of coalesce, postgres CAST?
      localVotes: parseInt(this.upvotes),
      upvoted: false,
      downvoted: false
    }
  },

  methods: {

    ...mapActions(['triggerErrorNotification']),

    ...mapMutations(['setVDialog', 'setErrorNotification', 'setBanNotification']),

    cardClicked: function () {
      window.open(this.url, '_blank')
    },

    upvote: async function () {
      try {
        if (this.userDetails) {
          await this.dbUpvote(this.postId)

          if (this.upvoted) {
            this.localVotes--
          } else if (this.downvoted) {
            this.localVotes += 2
            this.downvoted = false
          } else {
            this.localVotes++
          }
          this.upvoted = !this.upvoted
        } else {
          this.setVDialog(true)
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setBanNotification(true)
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    downvote: async function () {
      try {
        if (this.userDetails) {
          await this.dbDownvote(this.postId)
          if (this.downvoted) {
            this.localVotes++
          } else if (this.upvoted) {
            this.localVotes -= 2
            this.upvoted = false
          } else {
            this.localVotes--
          }
          this.downvoted = !this.downvoted
        } else {
          this.setVDialog(true)
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.setBanNotification(true)
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    deleteContent: function () {
      this.$emit('content-deleted')
    },

    markWatched: function () {
      this.$emit('mark-watched')
    }

    // markHidden: function () {
    //   this.$emit('mark-hidden')
    // }
  },

  computed: {
    imageDetails: function () {
      var src
      if (this.sizeSwitch) {
        if (this.layout === 2 || this.layout === 3) {
          src = 'https://dzbfexieadma8.cloudfront.net/communities/qtcinderella/q.png'
        } else {
          src = this.thumbnailUrl
        }
        return {
          size: 224,
          src: src
        }
      } else {
        if (this.layout === 2 || this.layout === 3) {
          src = 'https://dzbfexieadma8.cloudfront.net/communities/qtcinderella/q.png'
        } else {
          src = this.thumbnailUrl
        }
        return {
          size: 112,
          src: src
        }
      }
    },

    upvoteColor: function () {
      if (this.upvoted) {
        return 'green'
      } else {
        return 'gray'
      }
    },

    downvoteColor: function () {
      if (this.downvoted) {
        return 'red'
      } else {
        return 'gray'
      }
    },

    ...mapGetters(['userDetails'])

    // creatorInfo: function () {
    //   if (this.contentRoute === 'clips') {
    //     return {
    //       link: 'https://twitch.tv/' + this.creatorName,
    //       color: '#673AB7',
    //       icon: 'mdi-twitch',
    //       iconStyle: ''
    //     }
    //   } else {
    //     return {
    //       link: 'https://youtube.com/channel/' + this.channel_id,
    //       color: '#FF0000',
    //       icon: 'mdi-youtube',
    //       iconStyle: 'padding-bottom: 1.5px'
    //     }
    //   }
    // }
  },

  created: function () {
    if (this.init_upvoted == null) {
      this.upvoted = false
      this.downvoted = false
    } else if (this.init_upvoted === 1) {
      this.upvoted = true
      this.downvoted = false
    } else if (this.init_upvoted === -1) {
      this.upvoted = false
      this.downvoted = true
    }
  },

  watch: {
    postId: function () {
      this.localVotes = this.upvotes
      if (this.init_upvoted == null) {
        this.upvoted = false
        this.downvoted = false
      } else if (this.init_upvoted === 1) {
        this.upvoted = true
        this.downvoted = false
      } else if (this.init_upvoted === -1) {
        this.upvoted = false
        this.downvoted = true
      }
    }
  },

  props: {
    // post: Object
    postId: Number,
    thumbnailUrl: String,
    title: String,
    duration: String,
    creatorName: String,
    submitterName1: String,
    user_name: String,
    url: String,
    init_upvoted: Number,
    upvotes: String,
    dbUpvote: Function,
    dbDownvote: Function,
    category: String,
    primaryChipColor: String,
    secondaryChipLabel: String,
    secondaryChipColor: String,
    channel_id: String,
    chipLabel: String,
    submitterId: Number,
    badge_icon: String,
    badge_color: String,
    badge_style: String,
    badge_text: String,
    viewCount: String,
    sizeSwitch: Boolean,
    width: String,
    layout: Number,
    isMod: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

  .creator-background {
    border-radius: 50px;
    padding: 5px 8px 5px 8px;
  }

  .user-text:hover {
    color: white;
    font-weight: bold;
  }

  .thumbnail {
    position: relative;
  }

  .creator-link {
    text-decoration: none;
    color: inherit;
  }

  .submit-by {
    text-decoration: none;
    color: white;
  }

  .focus-none {
    outline: none !important;
    box-shadow: none !important;
  }

  .custom-title {
    line-height: 1.4em !important;
  }

  .card {
    color: #f9f9f9
  }
</style>
