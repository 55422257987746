<template>
  <v-card flat hover :to="'/' + routeParam" color="transparent">
    <v-img
      :src="avi_url"
      :alt="name + ' community image'"
      contain
    >
    </v-img>
    <!-- <v-card-actions v-if="links" class="">
      <v-btn icon v-if="links.twitter">
        <v-icon>
          mdi-twitter
        </v-icon>
      </v-btn>
      <v-btn icon v-if="links.twitch">
        <v-icon>
          mdi-twitch
        </v-icon>
      </v-btn>
      <v-btn icon v-if="links.youtube">
        <v-icon>
          mdi-youtube
        </v-icon>
      </v-btn>
    </v-card-actions> -->
    <v-card-title class="py-1 pl-2 text-subtitle-1 font-weight-medium">
      {{ name }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'CommunityCard',
  data () {
    return {

    }
  },
  props: {
    routeParam: String,
    name: String,
    avi_url: String,
    links: Object
  }
}
</script>

<style>

</style>
