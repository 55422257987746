<template>
  <v-list-item :id="'clip' + index" style="background-color: #0e0e10; display: flex; box-sizing: content-box; border-radius: 100;" class="pl-1 pt-1 pb-1">
      <v-list-item-avatar tile min-height="110" min-width="180" class="ma-0 pa-0" style="display: flex">
        <v-img :src="thumbnailUrl" min-height="110" min-width="180" style="flex-align: stretch;"></v-img>
        <!-- <span>
          <v-icon class="duration">
            mdi-delete
          </v-icon> -->
          <p class="mb-0 justify-end pr-2 pb-1 duration" style="display: flex">
            <span class="mr-0 duration-color" style="padding: 1px 2px 1px 2px;">
              &nbsp;{{ duration }}&nbsp;
            </span>
          </p>
        <!-- </span> -->
      </v-list-item-avatar>
      <!-- <div>
      <h5>{{ title }} </h5>
      </div> -->
      <v-list-item-content class="py-2 align-content-start align-start pl-2" style="flex-wrap: wrap">
        <v-list-item-title style=" font-size: 14px; font-weight: bold; word-break: keep-all !important;" v-line-clamp="2" class="custom-title mb-1">
          {{ title }}
        </v-list-item-title>

        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div>
            <v-card-subtitle class="pa-0" style="margin-bottom: -2px; font-size: 12px">
                <a :href="this.creatorInfo.link" target="_blank" @click.stop @mousedown.stop @touchstart.stop :style="'color: #FFFFFFB3'" class="creator-link user-text creator-background pa-0">
                    <v-icon size="13" :style="creatorInfo.listIconStyle" :color="creatorInfo.color" light>
                        {{ creatorInfo.listIcon }}
                    </v-icon>
                    {{ creatorName }}
                </a>
            </v-card-subtitle>

            <v-list-item-subtitle :style="'font-size: 12px;' + submitByClass">
                submitted by:
                <router-link :to="'/user/' + this.username + '/' + this.contentRoute" class="user-text submit-by">
                    {{ submitterName1 }}
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-show="badgeIcon" :small="badgeIcon === 'mdi-sword mdi-flip-h'" :dense="badgeIcon === 'mdi-discord'" :color="badgeColor" :style="badgeStyle" v-on="on" v-bind="attrs">
                                {{ badgeIcon }}
                            </v-icon>
                        </template>
                        <span style="font-size: 12px;">
                            {{ badgeText }}
                        </span>
                    </v-tooltip>
                </router-link>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="viewCount" style="font-size: 12px;">
                {{ formattedViewCount }} views &#8226; {{ formattedSubmitDate }}
            </v-list-item-subtitle>
        </div>

        <!-- <span>
          <v-icon x-small>
            mdi-delete
          </v-icon>
        </span> -->

        <div style="align-self: end;" class="ml-2 cat-display">
          <!-- <v-chip x-small style="font-size: 12px;" :color="cat_color" @click.prevent="$emit('primary-chip-selected')" @click.stop @mousedown.stop @touchstart.stop>
              {{ category }}
          </v-chip> -->

          <v-list-item-subtitle :style="'font-size: 12px;' + upvoteStyle">
            <v-icon color="" style="bottom: 1px;">
              mdi-chevron-up
            </v-icon>
          {{ upvotes }}
          </v-list-item-subtitle>
        </div>
      </div>

        <!-- <v-list-item-subtitle style="font-size: 12px;">
            submitted by: <span style="font-weight: bold;"> {{ submitterName1 }} </span>
        </v-list-item-subtitle> -->
      </v-list-item-content>
      <!-- <v-list-item-subtitle style="font-size: 14px;">
          {{ title }}
      </v-list-item-subtitle> -->
  </v-list-item>
</template>

<script>
import { getCreatorInfo, getFormattedViews, getFormattedSubmitDate } from '../utils/formatter'
export default {
  name: 'PlayerVideoCard',

  computed: {
    formattedViewCount: function () {
      return getFormattedViews(this.viewCount)
    },

    formattedSubmitDate: function () {
      return getFormattedSubmitDate(this.submitTimestamp)
    },

    creatorInfo: function () {
      return getCreatorInfo(this.contentType, this.channelId)
    },

    submitByClass: function () {
      if (this.badgeIcon) {
        return 'margin-bottom: -2px; margin-top: -2px;'
      } else {
        return 'padding-top: 3px; padding-bottom: 3px;'
      }
    },

    upvoteStyle: function () {
      if (this.viewCount) {
        return 'margin-bottom: -5px'
      } else {
        return 'margin-bottom: -3px'
      }
    }
  },

  props: {
    thumbnailUrl: String,
    title: String,
    submitterName1: String,
    viewCount: String,
    submitTimestamp: String,
    contentType: Number,
    channelId: String,
    category: String,
    cat_color: String,
    badgeIcon: String,
    badgeColor: String,
    badgeStyle: String,
    badgeText: String,
    creatorName: String,
    username: String,
    contentRoute: String,
    upvotes: String,
    index: Number,
    isMod: {
      type: Boolean,
      default: false
    },
    duration: String
  }
}
</script>

<style scoped>

  @media only screen and (max-width: 1264px) {
    .cat-display {
      display: none;
    }
  }

  .custom-title {
    line-height: 1.4em !important;
    height: 2.8em;
    white-space: normal;
  }

  .user-text:hover {
    color: white;
    font-weight: bold;
  }

  .submit-by {
    text-decoration: none;
    color: white;
  }

  .creator-link {
    text-decoration: none;
  }

  .creator-background {
    border-radius: 50px;
    padding: 5px 8px 5px 8px;
  }

  .thumbnail {
    position: relative;
  }

  .duration {
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    border-radius: 3px;
  }

  .duration-color {
    color: white;
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
  }

  .mod-action-color {
    color: white;
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
</style>
