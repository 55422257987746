<template>
  <div>
    <v-container class="" style="max-width: 1100px;">

      <v-dialog v-model="vDialog" max-width="500px" >
        <LoginCard v-if="!userDetails">
        </LoginCard>
      </v-dialog>

      <h1>
        Browse Communities
      </h1>

      <!-- <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-1" color="transparent">
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-on="on" v-bind="attrs" class="mt-3">
            <v-icon left>
              {{ filter.icon }}
            </v-icon>
            {{ filter.name }}
            <v-icon right>
              mdi-menu-down
            </v-icon>
          </v-chip>
        </template>

        <v-list class="cursor">
          <v-list-item-group v-model="filterIndex" mandatory v-on:change="filterChange()">
            <v-list-item v-for="(item, index) in filterItems" :key="index" :value="index">
              <v-icon left small>
                {{ item.icon }}
              </v-icon>
              <v-list-item-title style="font-size: 14px;">{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <div v-if="filter.name === 'All' || filter.name === 'Games'" class="mt-3">
        <h2>
          Games
        </h2>
        <v-row wrap align-content-start class="mt-1" no-gutters>
          <v-col v-for="(item, index) in communities.games" :key="index" class="five-cols-b four-cols-b three-cols-b one-col-b pr-3">
            <CommunityCard v-bind="item">
            </CommunityCard>
          </v-col>
        </v-row>
      </div> -->

      <div v-if="filter.name === 'All' || filter.name === 'Streamers'" class="mt-3">
        <h2>
          Streamers
        </h2>
        <v-row wrap align-content-start class="mt-1" no-gutters>
          <!-- without changing the key from just the index, a card can be reused for game / streamer and mess up the layout -->
          <v-col v-for="(item, index) in communities.streamers" :key="index + 1 * -1" class="five-cols-b four-cols-b three-cols-b one-col-b pr-3 mb-3">
            <CommunityCard v-bind="item">
            </CommunityCard>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CommunityCard from '../components/CommunityCard'
import LoginCard from '../components/LoginCard'
export default {
  name: 'Community',
  components: { CommunityCard, LoginCard },
  data () {
    return {
      filterIndex: 0,
      filterList: [
        'All',
        'Games',
        'Streamers'
      ],
      filterItems: [
        {
          name: 'All',
          icon: 'mdi-web'
        },
        {
          name: 'Games',
          icon: 'mdi-google-controller'
        },
        {
          name: 'Streamers',
          icon: 'mdi-video-outline'
        }
      ]
    }
  },

  methods: {
    filterChange: function () {
      // console.log(typeof this.filter)
    }
  },

  computed: {
    ...mapState({
      communityState: state => state.twitterAuth.communityState,
      userDetails: state => state.twitterAuth.userDetails,
      vDialog: state => state.twitterAuth.vDialog
    }),

    communities: function () {
      var items = {
        games: [],
        streamers: []
      }
      for (const comm in this.communityState) {
        if (this.communityState[comm].type === 1) {
          items.games.push(this.communityState[comm])
        } else {
          items.streamers.push(this.communityState[comm])
        }
      }
      return items
    },

    filter: {
      get () {
        return this.filterItems[this.filterIndex]
      },

      set (index) {
        this.filterIndex = index
      }
    }
  },

  created: function () {
    // this.printCommunityState()
  }
}

</script>

<style scoped>

  @media only screen and (min-width: 1100px) {
    .five-cols-b {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }

  }

  @media only screen and (min-width: 883px) and (max-width: 1099px) {
    .four-cols-b {
      width: 25%;
      max-width: 25%;
      flex-basis: 25%;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 882px) {
    .three-cols-b {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }

  @media only screen and (max-width: 600px) {
    .one-col-b {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }
</style>
