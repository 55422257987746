import Api from './Api'

export default {
  getContent (sortBy, category, offset, time, gameName, dur, typeId, secCat, w, creator, tabGroup, fromDate, toDate) {
    return Api().get('videos',
      {
        withCredentials: true,
        params: {
          sortBy: sortBy,
          category: category,
          offset: offset,
          time: time,
          gameName: gameName,
          typeId: typeId,
          dur: dur,
          secCat: secCat,
          w: w,
          creator: creator,
          tabGroup: tabGroup,
          fromDate: fromDate,
          toDate: toDate
        }
      }
    )
  },

  getUserContent (sortBy, userId, offset, time, dur, typeId, favorite) {
    return Api().get('user/videos',
      {
        withCredentials: true,
        params: {
          sortBy: sortBy,
          userId: userId,
          offset: offset,
          time: time,
          typeId: typeId,
          dur: dur,
          favorite: favorite
        }
      }
    )
  },

  uploadContent (uploadURL, videoCategory, gameName, secCat, title, context, tabGroup, santa) {
    return Api().post('videos/submit',
      {
        uploadURL: uploadURL,
        videoCategory: videoCategory,
        gameName: gameName,
        secCat: secCat,
        title: title,
        context: context
      },
      { withCredentials: true }
    )
  },

  deleteContent (postId) {
    return Api().delete(`videos/delete/${postId}`,
      {
        withCredentials: true
      })
  },

  markWatched (postId, gameId, hide, value) {
    return Api().post('videos/watched',
      {
        postId: postId,
        gameId: gameId,
        hide: hide,
        value: value
      },
      {
        withCredentials: true
      }
    )
  },

  upvote (postId) {
    return Api().post('videos/upvote',
      {
        postId: postId
      },
      { withCredentials: true }
    )
  },

  downvote (postId) {
    return Api().post('videos/downvote',
      {
        postId: postId
      },
      { withCredentials: true }
    )
  },

  favoriteContent (postId) {
    return Api().post('videos/favorite',
      {
        postId: postId
      },
      { withCredentials: true }
    )
  }
}
