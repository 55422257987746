<template>
  <v-container class="justify-center" style="max-width: 900px;">
    <v-dialog v-model="vDialog" max-width="500px" >
      <LoginCard v-if="!userDetails">
      </LoginCard>
    </v-dialog>

    <h1 class="mb-4 text-center">
        Leaderboard
    </h1>

    <v-select
      class="mx-auto mb-6"
      outlined
      hide-details
      placeholder="Select Community"
      :items="items"
      v-model="selected"
      item-text="name"
      :item-value="itemValue"
      @change="commChange"
      :menu-props="{ 'offset-y': true, 'transition': 'slide-y-transition' }"
      style="width: 300px; font-size: 14px !important; font-weight: bold;"
    >
      <template v-slot:item="data">
        <v-list-item-avatar :tile="data.item.type !== 2" class="px-">
          <img :src="data.item.avi_url">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="font-size:14px;" class="px-">
            {{ data.item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:selection="data">
        <v-list-item-avatar :tile="data.item.type !== 2" class="px-0">
          <img :src="data.item.avi_url">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="font-size:20px;" class="px-0">
            {{ data.item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>

    <div class="justify-center d-flex mb-4">
      <v-btn-toggle v-model="time" mandatory @change="getRank">
        <v-btn :value="1">
        <!-- :to="{ name: 'Videos', params: { content: 'videos', sortByUrl: encodeToUrl(this.sortBy) }, query: timeQuery}" -->
          All Time
        </v-btn>
        <!-- <v-btn value="clips" to="/clips" color="transparent"> -->
        <v-btn :value="2">
          Weekly
        </v-btn>
      </v-btn-toggle>
    </div>

    <!-- <p class="subtitle mb-0 text-center">Updated once per day.</p> -->
    <p class="subtitle text-center">Last Updated: {{ formattedTimestamp }}</p>

    <v-simple-table class="mt-3">
      <template v-slot:default>
        <!-- <thead>
          <tr>
            <th>
              Rank
            </th>
            <th>
              <span style="padding-left: 52px;">
                Name
              </span>
            </th>
            <th>
              Score
            </th>
            <th></th>
          </tr>
        </thead> -->
        <tbody>
          <tr v-for="(item, index) in scores1" :key="index" @click="rowClicked(item)" style="cursor: pointer;">
            <td>
              {{ index + 1 }}
              <v-icon v-if="index === 0" right color="yellow">
                mdi-crown
              </v-icon>
            </td>
            <td>
              <v-avatar size="40" class="mr-2">
                <v-img :src="item.avatarUrl"></v-img>
              </v-avatar>
              {{ item.display_name }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :small="item.badge_icon === 'mdi-sword mdi-flip-h'" :dense="item.badge_icon === 'mdi-discord'" :color="item.badge_color" :style="item.badge_style" v-on="on" v-bind="attrs">
                    {{ item.badge_icon }}
                  </v-icon>
                </template>
                <span style="font-size: 14px;">
                  {{ item.badge_text }}
                </span>
              </v-tooltip>
            </td>
            <td>
              {{ item.score }}
            </td>
            <td>
              <v-icon v-if="$vuetify.breakpoint.smAndUp" @click.stop="loginIconClicked(item.username, item.auth_type)" @mousedown.stop @touchstart.stop>
                {{ getAuthIcon(item.auth_type) }}
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import RankingService from '../services/RankingService'
import LoginCard from '../components/LoginCard'
import { mapState } from 'vuex'
import VueRouter from 'vue-router'
export default {
  data () {
    return {
      scores1: [],
      selected: {
        game_id: null,
        routeParam: null
      },
      timestamp: null,
      time: 1
      // gameId: this.selectedInit
    }
  },

  components: { LoginCard },

  methods: {
    getRank: async function () {
      const res = await RankingService.getRanking(this.selected.game_id, this.time)
      this.scores1 = res.data.scores
      this.timestamp = res.data.timestamp
    },

    commChange: async function () {
      // should cache here
      var route = this.selected.routeParam ? `/leaderboard/${this.selected.routeParam}` : '/leaderboard'
      this.$router.push(route).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // do nothing!!
        }
      })
      await this.getRank()
    },

    itemValue: function (item) {
      return {
        game_id: item.game_id,
        routeParam: item.routeParam
      }
    },

    rowClicked: function (item) {
      this.$router.push(`/user/${item.username}`).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // do nothing!!
        }
      })
    },

    loginIconClicked: function (username, authType) {
      if (authType === 2) {
        window.open('https://twitch.tv/' + username, '_blank')
      } else if (authType === 1) {
        window.open('https://twitter.com/' + username, '_blank')
      }
    },

    getAuthIcon: function (authType) {
      switch (authType) {
        case 1:
          return 'mdi-twitter'
        case 2:
          return 'mdi-twitch'
        case 3:
          return 'mdi-google'
      }
    }
  },

  computed: {
    ...mapState({
      communityState: state => state.twitterAuth.communityState,
      userDetails: state => state.twitterAuth.userDetails,
      vDialog: state => state.twitterAuth.vDialog
    }),

    selectedInit: function () {
      if (this.game) {
        for (const comm of Object.values(this.communityState)) {
          if (comm.routeParam === this.game) {
            return {
              game_id: comm.game_id,
              routeParam: comm.routeParam
            }
          }
        }
      }
      return {
        game_id: null,
        routeParam: null
      }
    },

    items: function () {
      const all = {
        name: 'All Communities',
        avi_url: 'https://pbs.twimg.com/profile_images/1490188145555656706/3s6J-ztO_normal.png',
        type: 2,
        game_id: null,
        routeParam: null
      }
      return [all, ...Object.values(this.communityState)]
    },

    formattedTimestamp: function () {
      try {
        if (this.timestamp) {
          const date = new Date(this.timestamp)
          var day = date.getDate()
          var month = date.getMonth() + 1
          var year = date.getFullYear()
          var hours = date.getHours()
          var minutes = date.getMinutes()
          var seconds = date.getSeconds()
          if (day < 10) {
            day = `0${day}`
          }
          if (month < 10) {
            month = `0${month}`
          }
          if (hours < 10) {
            hours = `0${hours}`
          }
          if (minutes < 10) {
            minutes = '0' + minutes
          }
          if (seconds < 10) {
            seconds = '0' + seconds
          }
          return month + '-' + day + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds
        }
        return this.timestamp
      } catch (error) {
        return this.timestamp
      }
    }
  },

  mounted: async function () {
    this.selected = this.selectedInit
    await this.getRank()
  },

  props: {
    game: String
  }
}
</script>

<style scoped>
  >>> .v-select .v-select__selections input {
    display: none;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .20);
  }

  .subtitle {
    font-style: italic;
    font-size: 12px;
    color: #FFFFFFB3;
  }
</style>
