var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{style:('z-index: 100;' + _vm.barStyle),attrs:{"dense":"","app":""}},[_c('v-toolbar-title',{staticClass:"mr-4",staticStyle:{"cursor":"pointer"},on:{"click":_vm.logoClicked}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.xs ? 'SSC' : 'SmashScene')+" ")]),_c('v-toolbar-items',[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-btn',{attrs:{"text":"","to":"/communities"}},[_vm._v(" Communities ")]):_c('v-btn',{attrs:{"icon":"","to":"/communities"}},[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-btn',{attrs:{"text":"","to":"/leaderboard"}},[_vm._v(" Leaderboard ")]):_c('v-btn',{attrs:{"icon":"","to":"/leaderboard"}},[_c('v-icon',[_vm._v(" mdi-podium ")])],1)],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-autocomplete',{staticClass:"m-auto",staticStyle:{"max-width":"400px"},attrs:{"outlined":"","filled":"","flat":"","items":_vm.items,"item-text":"name","item-value":"routeParam","no-data-text":"no results found.","dense":"","append-icon":"mdi-magnify","hide-details":"","menu-props":{ transition: false },"placeholder":"search communities..."},on:{"input":_vm.inputReceived},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',{staticClass:"px-0",attrs:{"tile":data.item.type !== 2}},[_c('img',{attrs:{"src":data.item.avi_url}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"px-0",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}}],null,false,856324245),model:{value:(_vm.searchModel),callback:function ($$v) {_vm.searchModel=$$v},expression:"searchModel"}}):_vm._e(),_c('v-spacer'),_c('span',{staticClass:"justify-end align-center",style:(_vm.rightSpanStyle)},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}],null,false,1213369270),model:{value:(_vm.mobileSearch),callback:function ($$v) {_vm.mobileSearch=$$v},expression:"mobileSearch"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Search for Communities ")]),_c('v-card-text',[_c('v-autocomplete',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","filled":"","flat":"","items":_vm.items,"item-text":"name","item-value":"routeParam","no-data-text":"no results found.","dense":"","append-icon":"mdi-magnify","hide-details":"","placeholder":"search communities..."},on:{"input":_vm.inputReceived},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',{staticClass:"px-0",attrs:{"tile":data.item.type !== 2}},[_c('img',{attrs:{"src":data.item.avi_url}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"px-0",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}}],null,false,856324245),model:{value:(_vm.searchModel),callback:function ($$v) {_vm.searchModel=$$v},expression:"searchModel"}})],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-menu',{key:"privacy-menu",attrs:{"bottom":"","offset-y":true,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-note-text ")])],1)]}}],null,false,3961436740)},[_c('v-list',{staticClass:"cursor"},[_c('v-list-item',{attrs:{"href":"https://smashscene.com/privacy_policy.html"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-note-text ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Privacy Policy")])],1)],1),_c('v-list-item',{attrs:{"href":"https://smashscene.com/cookie_policy.html"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-cookie ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cookie Policy")])],1)],1)],1)],1):_vm._e(),(_vm.userDetails)?_c('v-menu',{key:"user-menu",attrs:{"bottom":"","offset-y":true,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"cursor py-2",attrs:{"color":"accent","size":"40"},on:{"click":_vm.userClicked}},'v-avatar',attrs,false),on),[(_vm.userDetails.avatarUrl)?_c('img',{attrs:{"src":_vm.userDetails.avatarUrl}}):_c('p',[_vm._v(_vm._s(_vm.userDetails.username))])])]}}],null,false,2258981759)},[_c('v-list',{staticClass:"cursor"},[_c('v-list-item',{attrs:{"to":'/user/' + _vm.userDetails.username}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Profile")])],1)],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-star-face ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Score")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(parseInt(_vm.userDetails.video_score) + parseInt(_vm.userDetails.clips_score))+" ")])],1)],1),_c('v-list-item',{attrs:{"href":_vm.profileRefreshUrl}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-reload ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Refresh User Details")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.setDeleteRequested(true)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete Account")])],1)],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-logout ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1):(_vm.$vuetify.breakpoint.smAndUp)?_c('v-btn',{attrs:{"outlined":"","dark":"","rounded":""},on:{"click":function($event){return _vm.setVDialog(true)}}},[_c('span',[_vm._v(" Login / Sign Up ")])]):_c('v-menu',{key:"anon-menu",attrs:{"bottom":"","offset-y":true,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-menu ")])],1)]}}])},[_c('v-list',{staticClass:"cursor"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{attrs:{"dark":"","rounded":"","outlined":""},on:{"click":function($event){return _vm.setVDialog(true)}}},[_c('span',[_vm._v(" Login / Sign Up ")])])],1)],1),_c('v-list-item',{attrs:{"href":"https://smashscene.com/privacy_policy.html"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-note-text ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Privacy Policy")])],1)],1),_c('v-list-item',{attrs:{"href":"https://smashscene.com/cookie_policy.html"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-cookie ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cookie Policy")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }