<template>
  <v-img contain height="24px" width="24px" src="@/assets/discord.png"></v-img>
</template>

<script>
export default {
  name: 'DiscordIcon'
//   props: {
//     imgsrc: String
//   }
}
</script>
