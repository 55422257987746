<template>
  <v-card class="card-width" outlined>
    <v-form v-model="valid" ref="submitForm">
      <v-card-title :height="2000">
        <span>{{ titleLabel }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="inputLabel"
          v-model="url"
          :rules="[urlRules]">
        </v-text-field>
        <v-text-field
          :label="userTitleLabel"
          v-if="userTitleLabel"
          v-model="title"
          counter="100"
          :rules="[titleRules]">
        </v-text-field>
        <v-select
          v-model="selectedCategory"
          label="Category"
          :items="categoryItems"
          item-text="name"
          item-value="category_id"
          :rules="[categoryRules]"
        >
          <template v-slot:selection="data">
            <v-chip :color="data.item.color">
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-select>
        <v-select
          v-if="secCatEnabled"
          v-model="secSelectedCategory"
          :label="secCatOptional ? secCatLabel + ' (Optional)' : secCatLabel"
          :items="secondaryItems"
          item-text="name"
          item-value="category_id"
          :rules="[secCatRules]"
        >
          <template v-slot:selection="data">
            <v-chip :color="data.item.color">
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-select>
        <v-select
          v-if="contentType === 'santa'"
          class=""
          v-model="santa"
          label="Streamer (Leave blank to use original channel)"
          :items="['Use clip\'s channel', 'Brittt', 'erobb221', 'HasanAbi', 'Ludwig', 'Maya', 'Myth', 'Nmplol', 'prezoh', 'QTCinderella', 'Squeex', 'Stanz', 'willneff']"
        >
        </v-select>

        <!-- <v-textarea
          v-if="contentType === 'clips'"
          label="Context (Optional)"
          :counter="300"
          :rules="[contextRules]"
          v-model="context"
          rows="3"
          row-height="20"
          auto-grow
        >
        </v-textarea> -->

        <!-- <p style="color: #b8b8b8; font-size: 15px" class="my-2 pb-0">Don't forget to post Shitcamp clips on the <a target="_blank" href="https://shitcamp.live/clips">official site!</a></p> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closeClicked()"
          outlined
        >
          Close
        </v-btn>
        <v-btn
          @click="submitClicked"
          :color="$vuetify.theme.dark ? 'white' : 'black'" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'SubmissionCard',
  data () {
    return {
      url: null,
      selectedCategory: null,
      // tricky... if its null 'None' will start autoselected
      secSelectedCategory: undefined,
      valid: false,
      title: null,
      context: null,
      santa: null
    }
  },

  methods: {
    ...mapMutations(['setVDialog']),

    urlRules: function (value) {
      for (const pattern of this.validUrlRegex) {
        if (pattern.test(value)) {
          return true
        }
      }
      return this.errorLabel
    },

    titleRules: function (value) {
      if (this.title && this.title.length > 100) {
        return 'Titles can have a maximum of 100 characters'
      }
      return true
    },

    contextRules: function (value) {
      if (this.context && this.context.length > 300) {
        return 'Context can have a maximum of 100 characters'
      }
      return true
    },

    categoryRules: function (value) {
      return value !== null || 'No category selected'
    },

    secCatRules: function (value) {
      if (!this.secCatOptional) {
        // should really have the label lowercase in the middle but doesn't matter for now
        return value !== null || 'No game selected'
      } else {
        return true
      }
    },

    submitClicked: function () {
      this.$refs.submitForm.validate()
      if (this.valid) {
        this.$emit('upload-video', { url: this.url, category_id: this.selectedCategory, sec_cat_id: this.secSelectedCategory, title: this.title, context: this.context, santa: this.santa })
        this.$refs.submitForm.reset()
      }
    },

    closeClicked: function () {
      this.setVDialog(false)
      this.$refs.submitForm.reset()
    }
  },

  computed: {
    secondaryItems: function () {
      if (this.secCatEnabled && this.secCatOptional) {
        var none = {
          category_id: null,
          game_id: null,
          color: null,
          name: 'None'
        }
        return [none, ...this.secCategoryItems]
      } else {
        return this.secCategoryItems
      }
    },

    validUrlRegex: function () {
      return this.validUrlPattern.map(pattern => new RegExp(pattern))
    }
  },

  props: {
    titleLabel: String,
    userTitleLabel: String,
    inputLabel: String,
    errorLabel: String,
    categoryItems: Array,
    secCategoryItems: Array,
    validUrlPattern: Array,
    secCatOptional: Boolean,
    secCatLabel: String,
    secCatEnabled: Boolean,
    contentType: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
  .card-width {
    width: 400px;
  }
</style>
