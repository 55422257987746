<template>
    <v-app-bar dense app :style="'z-index: 100;' + barStyle">

      <!-- width of xl is 274.13, width of right span must match to center -->
      <v-toolbar-title @click="logoClicked" style="cursor: pointer;" class="mr-4">
        {{ $vuetify.breakpoint.xs ? 'SSC' : 'SmashScene' }}
        <!-- <v-icon size=36 class="align-self-center">
          mdi-web
        </v-icon> -->
      </v-toolbar-title>
      <v-toolbar-items>
        <v-btn v-if="$vuetify.breakpoint.lgAndUp" text to="/communities">
          Communities
        </v-btn>
        <v-btn v-else icon to="/communities">
          <v-icon>
            mdi-content-copy
          </v-icon>
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.lgAndUp" text to="/leaderboard">
          Leaderboard
        </v-btn>
        <v-btn v-else icon to="/leaderboard">
          <v-icon>
            mdi-podium
          </v-icon>
        </v-btn>

      </v-toolbar-items>

      <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>

      <!-- ### SEARCH ### -->

      <!-- style="border: None; !important; max-width: 400px; position: absolute; left: 50%; transform: translate(-50%,0); width: 400px;" -->
      <v-autocomplete
        v-if="$vuetify.breakpoint.lgAndUp"
        class="m-auto"
        outlined
        filled
        flat
        :items="items"
        item-text="name"
        item-value="routeParam"
        no-data-text="no results found."
        @input="inputReceived"
        v-model="searchModel"
        dense
        append-icon="mdi-magnify"
        hide-details
        style="max-width: 400px;"
        :menu-props="{ transition: false }"
        placeholder="search communities..."
      >
        <template v-slot:item="data">
            <v-list-item-avatar :tile="data.item.type !== 2" class="px-0">
              <img :src="data.item.avi_url">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size:14px;" class="px-0">
                {{ data.item.name }}
              </v-list-item-title>
            </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-spacer></v-spacer>

      <span :style="rightSpanStyle" class="justify-end align-center">

        <!-- ### MOBILE SEARCH ### -->
        <v-dialog v-model="mobileSearch" v-if="$vuetify.breakpoint.mdAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class=""
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Search for Communities
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                outlined
                filled
                flat
                :items="items"
                item-text="name"
                item-value="routeParam"
                no-data-text="no results found."
                @input="inputReceived"
                v-model="searchModel"
                dense
                append-icon="mdi-magnify"
                hide-details
                style="max-width: 400px;"
                placeholder="search communities..."
              >
                <template v-slot:item="data">

                    <v-list-item-avatar :tile="data.item.type !== 2" class="px-0">
                      <img :src="data.item.avi_url">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title style="font-size:14px;" class="px-0">
                        {{ data.item.name }}
                      </v-list-item-title>
                    </v-list-item-content>

                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- ### PRIVACY MENU ### -->
        <v-menu v-if="$vuetify.breakpoint.smAndUp" bottom :offset-y="true" transition="slide-y-transition" key="privacy-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class='mr-3'>
              <v-icon>
                mdi-note-text
              </v-icon>
            </v-btn>
          </template>
          <v-list class="cursor">
            <v-list-item href="https://smashscene.com/privacy_policy.html">
              <v-list-item-icon>
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Privacy Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://smashscene.com/cookie_policy.html">
              <v-list-item-icon>
                <v-icon>
                  mdi-cookie
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cookie Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- ### USER MENU ### -->
        <v-menu v-if="userDetails" bottom :offset-y="true" transition="slide-y-transition" key="user-menu">
          <template v-slot:activator="{ on, attrs }" v-bind="userDetails">
            <v-avatar v-on="on" v-bind="attrs" color="accent" size="40" @click="userClicked" class="cursor py-2">
              <img v-if="userDetails.avatarUrl" :src="userDetails.avatarUrl">
              <p v-else>{{ userDetails.username }}</p>
            </v-avatar>
          </template>
          <v-list class="cursor">
            <v-list-item :to="'/user/' + userDetails.username">
              <v-list-item-icon>
                <v-icon>
                  mdi-account
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop>
              <v-list-item-icon>
                <v-icon>
                  mdi-star-face
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Score</v-list-item-title>
                <v-list-item-subtitle>
                  {{ parseInt(userDetails.video_score) + parseInt(userDetails.clips_score) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item @click.stop>
              <v-list-item-icon>
                <v-icon>
                  mdi-weather-night
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dark Mode</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  v-model="$vuetify.theme.dark"
                >
                </v-switch>
              </v-list-item-action>
            </v-list-item> -->
            <v-list-item :href="profileRefreshUrl">
              <v-list-item-icon>
                <v-icon>
                  mdi-reload
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Refresh User Details</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setDeleteRequested(true)">
              <v-list-item-icon>
                <v-icon color="red">
                  mdi-delete
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Delete Account</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>
                  mdi-logout
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- ### DESKTOP LOGIN BUTTON ### -->
        <v-btn outlined dark rounded class="" v-else-if="$vuetify.breakpoint.smAndUp" @click="setVDialog(true)">
          <span>
            Login / Sign Up
          </span>
        </v-btn>

        <!-- ### MOBILE RIGHT MENU ### -->
        <v-menu v-else bottom :offset-y="true" transition="slide-y-transition" key="anon-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-menu
              </v-icon>
            </v-btn>
          </template>

          <v-list class="cursor">
            <!-- <v-list-item @click.stop>
              <v-list-item-icon>
                <v-icon>
                  mdi-weather-night
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dark Mode</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  v-model="$vuetify.theme.dark"
                >
                </v-switch>
              </v-list-item-action>
            </v-list-item> -->

            <v-list-item>
              <v-list-item-content>
                <v-btn
                  dark
                  rounded
                  outlined
                  @click="setVDialog(true)"
                >
                  <span>
                    Login / Sign Up
                  </span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://smashscene.com/privacy_policy.html">
              <v-list-item-icon>
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Privacy Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://smashscene.com/cookie_policy.html">
              <v-list-item-icon>
                <v-icon>
                  mdi-cookie
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cookie Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
      <!-- <v-img class="mx-0 cursor" contain src='../assets/twitter_sign_in.png' max-height=28 max-width=158></v-img> -->
    </v-app-bar>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import VueRouter from 'vue-router'
export default {
  name: 'AppBar',
  data () {
    return {
      // userDetails: null
      searchModel: null,
      twitterApiUrl: process.env.VUE_APP_API_BASE_URL + '/twitter',
      mobileSearch: false
    }
  },
  methods: {

    ...mapActions(['getUserDetails', 'logout']),

    ...mapMutations(['setVDialog', 'setDeleteRequested']),

    userClicked: function () {
      // console.log('communityState: ' + this.communityState)
    },

    logoClicked: function () {
      this.$router.push('/').catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // do nothing!!
        }
      })
    },

    inputReceived: function (selection) {
      // put content in vuex and can push to specific page
      this.$router.push({ name: 'GameVideos', params: { game: selection, content: 'videos' } }).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
        }
      }).finally(() => {
        this.searchModel = null
        this.mobileSearch = false
      })
    }
  },

  computed: {
    ...mapGetters(['userDetails']),

    ...mapState({
      communityState: state => state.twitterAuth.communityState
    }),

    profileRefreshUrl: function () {
      if (this.userDetails) {
        if (this.userDetails.auth_type === 2) {
          return process.env.VUE_APP_API_BASE_URL + '/twitch'
        } else if (this.userDetails.auth_type === 3) {
          return process.env.VUE_APP_API_BASE_URL + '/google'
        } else {
          return process.env.VUE_APP_API_BASE_URL + '/twitter'
        }
      } else {
        return process.env.VUE_APP_API_BASE_URL + '/twitter'
      }
    },

    barStyle: function () {
      return this.$vuetify.theme.dark ? 'border-bottom: 1px solid rgba(255,255,255,.12) !important; background-color: #1a1a1b;' : 'border-bottom: 1px solid rgba(0,0,0,.12) !important; background-color: white;'
    },

    rightSpanStyle: function () {
      return this.$vuetify.breakpoint.lgAndUp ? 'display: flex; width: 401.29px;' : 'display: flex;'
    },

    items: function () {
      // console.log('items')
      // if (this.searchModel) {
      //   return Object.values(this.communityState)
      // } else {
      //   return []
      // }
      return Object.values(this.communityState)
    }
  },

  created: async function () {
    console.log('@slink_dev')
    await this.getUserDetails()
  }

}
</script>

<style>

</style>
