export const SECOND = 1000
export const MINUTE = SECOND * 60
export const HOUR = MINUTE * 60
export const DAY = HOUR * 24
export const MONTH = DAY * 30

export const sortByItems = {
  hot: {
    label: 'Hot',
    value: 'hot',
    icon: 'mdi-fire'
  },
  new: {
    label: 'New',
    value: 'new',
    icon: 'mdi-decagram-outline'
  },
  top: {
    label: 'Top',
    value: 'top',
    icon: 'mdi-transfer-up'
  }
}

export const timeItems = {
  day: {
    label: 'Today',
    query: 'day'
  },
  week: {
    label: 'Week',
    query: 'week'
  },
  month: {
    label: 'Month',
    query: 'month'
  },
  year: {
    label: 'Year',
    query: 'year'
  },
  all: {
    label: 'All Time',
    query: 'all'
  }
}
